import { gql } from '@apollo/client'
import { ONE_COLUMN_ROW_FRAGMENT } from '../../../components/flexibleContent/fragments/oneColumnRow'
import { TWO_COLUMN_ROW_FRAGMENT } from '../../../components/flexibleContent/fragments/twoColumnRow'
import { THREE_COLUMN_ROW_FRAGMENT } from '../../../components/flexibleContent/fragments/threeColumnRow'
import { FOUR_COLUMN_ROW_FRAGMENT } from '../../../components/flexibleContent/fragments/fourColumnRow'
import { IFRAME_FRAGMENT } from '../../../components/flexibleContent/fragments/iframe'
import { SPACER_FRAGMENT } from '../../../components/flexibleContent/fragments/spacer'
import { ACCORDION_FRAGMENT } from '../../../components/flexibleContent/fragments/accordion'
import { PEOPLE_FRAGMENT } from '../../../components/flexibleContent/fragments/people'
import { TABS_FRAGMENT } from '../../../components/flexibleContent/fragments/tabs'
import { FlexibleContentFragment } from './__generated__/flexibleContent'
import React, { useMemo } from 'react'
import { getSection } from '../../../components/flexibleContent/fragments'
import { DISCLAIMER_FCP_FRAGMENT } from '../../../components/flexibleContent/fragments/disclaimer'
import { FORM_FRAGMENT } from '../../../components/flexibleContent/fragments/form'
import { LINK_GRID_FRAGMENT } from '../../../components/flexibleContent/fragments/linkGrid'
import { FEATURE_GRID_FRAGMENT } from '../../../components/flexibleContent/fragments/featureGrid'
import { FCP_CAROUSEL_FRAGMENT } from '../../../components/flexibleContent/fragments/carousel'
import { PRODUCT_TABLES_FRAGMENT } from '../../../components/flexibleContent/fragments/productTable'
import { CTA_FRAGMENT } from '../../../components/flexibleContent/fragments/cta'
import { ALT_BG_START_FRAGMENT, ALT_BG_END_FRAGMENT, AltBg } from '../../../components/flexibleContent/fragments/altBg'
import { FlexibleContent as FlexibleContentBase } from '../../../components/flexibleContent/flexibleContent'
import { RELATED_CONTENT_FRAGMENT } from '../../../components/flexibleContent/fragments/relatedContent'
import { FAQS_FRAGMENT } from '../../../components/flexibleContent/fragments/faqs'
import { QUICK_EXIT_CTA_FRAGMENT } from "../../../components/flexibleContent/fragments/quickExitCTA"
import { nonNull } from '../../../utils/nonNull'
import { iconObj } from '../../common/disclaimerIcons'
import {
  StyledPageDisclaimerBase,
  StyledPageDisclaimerContainer,
  StyledPageDisclaimerSymbol,
  StyledPageDisclaimerTitle,
  StyledPageDisclaimerWrapper,
} from '../styledPage'
import Grid from '@material-ui/core/Grid'
import { html } from '../../../utils/htmlParser'
import { LazyComponent } from '../../../components/lazyComponent/lazyComponent'
import { DisclaimerContext, DisclaimerIconMap, DisclaimerType } from '../../../context/disclaimerContext'

export type FlexibleContentProps = {
  content: FlexibleContentFragment[]
  entryId?: string
}

type CarryType = (FlexibleContentFragment | null | FlexibleContentFragment[])[];

export const FlexibleContent: React.VFC<FlexibleContentProps> = ({ entryId, content }) => {
  const id = entryId

  const generalDisclaimerBlock = content?.filter(x => x.__typename === 'flexibleContent_disclaimer_BlockType')?.length


  const altBgContent = content.reduce((carry: CarryType, col) => {
    let block = col
    
    if (col.__typename === 'flexibleContent_altBgStart_BlockType') {
      //@ts-ignore
      block = [col]
    }

    if (col.__typename === 'flexibleContent_altBgEnd_BlockType') {
      carry.push(null)

      return carry
    }

    if (Array.isArray(carry[carry.length-1])) {
      //@ts-ignore
      carry[carry?.length -1].push(block)
    } else {
      carry.push(block)
    }

    return carry
  }, []).filter(Boolean)

  const disclaimerArray = useMemo(() => {
    const sortedArray: DisclaimerType[] = []

    content.map((content, _) => {
      content?.__typename === 'flexibleContent_productTable_BlockType' && content?.table?.[0]?.__typename === 'rateTables_default_Entry' &&
      content?.table?.[0]?.rateTablePicker?.[0] && content?.table?.[0]?.rateTablePicker.map((rate, _) => {
        if ( rate?.__typename === 'rates_default_Entry') {
          if ( rate.homeLoanDisclaimers && rate.homeLoanDisclaimers?.length > 0 && rate?.homeLoanDisclaimers?.[0]?.__typename === 'homeLoanDisclaimers_default_Entry' ) {
            nonNull(rate?.homeLoanDisclaimers).map((item, _) => {
              {/** @ts-ignore */}

              if (item && item?.__typename === 'homeLoanDisclaimers_default_Entry') {
                {/** @ts-ignore */}

                if (sortedArray.some(sortedArray => sortedArray.disclaimerId === item?.disclaimerId)) {
                  return
                } else {
                  sortedArray.push(item as DisclaimerType)
                }
              }

              return null
            })
          }
          else if ( rate?.personalLoanDisclaimers && rate?.personalLoanDisclaimers?.length > 0 && rate?.personalLoanDisclaimers?.[0]?.__typename === 'personalloandisclaimers_default_Entry' ) {
            nonNull(rate.personalLoanDisclaimers).map((item, _) => {
              {/** @ts-ignore */}

              if (item && item?.__typename === 'personalloandisclaimers_default_Entry') {
                {/** @ts-ignore */}

                if (sortedArray.some(sortedArray => sortedArray.disclaimerId === item?.disclaimerId)) {
                  return
                } else {
                  sortedArray.push(item as DisclaimerType)
                }
              }

              return null
            })

            return null
          }

          return null
        }})   
    })

    return sortedArray
  }, [content])

  const iconMap: DisclaimerIconMap = useMemo(() => {
    const iconMap = new Map()

    for (const [i, disclaimer] of disclaimerArray.entries()) {
      iconMap.set(disclaimer.disclaimerId, <>{iconObj[i] ? iconObj[i] : `~${i}`}</>)
    }

    return iconMap
  }, [disclaimerArray])

  const pageId = useMemo(() => {
    return id as string
  }, [id])

  return (
    <DisclaimerContext.Provider value={{ pageId, iconMap, disclaimerArray }}>
      <FlexibleContentBase>
        {altBgContent.map((props, i) => {
          if (Array.isArray(props)) {
            //@ts-ignore
            const { colour } = props[0]

            const altBgSections = props.map((altBgProps, n) => {
              const Section = getSection(altBgProps)

              if (altBgProps.__typename === 'flexibleContent_altBgStart_BlockType') {
                return null
              }

              if (!Section) {
                console.warn(`Failed to locate section for ${altBgProps.__typename}`)
    
                return null
              }
    
              return (
                <LazyComponent key={n}>
                  {/** @ts-ignore */}
                  <Section { ...altBgProps } />
                </LazyComponent>
              )
            })

            return (
              <AltBg colour={colour} key={i}>
                {altBgSections}
              </AltBg>
            )
          }

          if (!props) {
            console.warn('Failed to locate section')

            return null
          }
          
          const Section = getSection(props)

          if (!Section) {
            console.warn(`Failed to locate section for ${props.__typename}`)

            return null
          }

          return (
            <LazyComponent key={i}>
              {/** @ts-ignore */}
              <Section { ...props } />
            </LazyComponent>
          )
        })}
        {(generalDisclaimerBlock === 0) && disclaimerArray && disclaimerArray.length > 0 &&
          <StyledPageDisclaimerContainer maxWidth={'lg'}>
            <Grid container direction={'column'} alignItems={'center'}>
              <Grid item>
                <StyledPageDisclaimerWrapper>
                  <StyledPageDisclaimerTitle id={`disclaimer_block_${pageId}`} title>{'INFORMATION YOU SHOULD KNOW'}</StyledPageDisclaimerTitle>
                  {disclaimerArray && nonNull(disclaimerArray).map((d, index) => {
                    return (
                      <StyledPageDisclaimerBase key={index} textBlock>
                        <StyledPageDisclaimerSymbol>
                          {iconMap.get(d.disclaimerId)}
                        </StyledPageDisclaimerSymbol>
                        {d?.disclaimerText && html(d.disclaimerText)}
                      </StyledPageDisclaimerBase>
                    )
                  })}
                </StyledPageDisclaimerWrapper>
              </Grid>
            </Grid>
          </StyledPageDisclaimerContainer>
        }
      </FlexibleContentBase>
    </DisclaimerContext.Provider>
  )
}

export const FLEXIBLE_CONTENT_FRAGMENT = gql`
  ${ONE_COLUMN_ROW_FRAGMENT}
  ${TWO_COLUMN_ROW_FRAGMENT}
  ${THREE_COLUMN_ROW_FRAGMENT}
  ${FOUR_COLUMN_ROW_FRAGMENT}
  ${IFRAME_FRAGMENT}
  ${SPACER_FRAGMENT}
  ${FORM_FRAGMENT}
  ${ACCORDION_FRAGMENT}
  ${PEOPLE_FRAGMENT}
  ${TABS_FRAGMENT}
  ${DISCLAIMER_FCP_FRAGMENT}
  ${LINK_GRID_FRAGMENT}
  ${FEATURE_GRID_FRAGMENT}
  ${RELATED_CONTENT_FRAGMENT}
  ${FCP_CAROUSEL_FRAGMENT}
  ${PRODUCT_TABLES_FRAGMENT}
  ${CTA_FRAGMENT}
  ${FAQS_FRAGMENT}
  ${QUICK_EXIT_CTA_FRAGMENT}
  ${ALT_BG_START_FRAGMENT}
  ${ALT_BG_END_FRAGMENT}

  fragment FlexibleContentFragment on flexibleContent_MatrixField {
    ...OneColumnRowFragment
    ...TwoColumnRowFragment
    ...ThreeColumnRowFragment
    ...FourColumnRowFragment
    ...iFrameFragment
    ...SpacerFragment
    ...FormFragment
    ...AccordionFragment
    ...PeopleFragment
    ...TabsFragment
    ...DisclaimerFCPFragment
    ...LinkGridFragment
    ...FeatureGridFragment
    ...FCPCarouselFragment
    ...ProductTablesFragment
    ...CtaFragment
    ...RelatedContentFragment
    ...FaqsFragment
    ...QuickExitCTAFragment
    ...AltBgStartFragment
    ...AltBgEndFragment
  }
`
