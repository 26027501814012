import { Button } from '../button/button'
import Typography from '@material-ui/core/Typography'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import { irem } from '../../utils'
import { Icon } from '../icon/icon'

export const StyledChevronIcon = styled(Icon)`
  transition: transform linear 0.5s;
`

export const StyledCardSection = styled(Grid)`
  border-left: unset;
  width: 100%;
  margin: 0 ${props => props.theme.typography.pxToRem(10)} 0 ${props => props.theme.typography.pxToRem(10)};

  ${props=> props.theme.breakpoints.up('md')} {
    border-left: 2px solid rgba(112, 112, 112, 0.3);
    margin: unset;

    && {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
`

export const StyledCardTitle = styled(Grid)`
  text-align: center;

  ${props => props.theme.breakpoints.up('md')} {
    padding-right: ${props => props.theme.typography.pxToRem(100)};
    text-align: left;

    && {
      padding-top: 0;
      padding-bottom: 0;
    }

    & > * {
      max-width: ${props => props.theme.typography.pxToRem(250)};
    }
  }

  > :last-child {
    margin-bottom: 0;
  }

  .MuiTypography-h3 {
    font-size: ${irem(23)};
    line-height: ${irem(34)};

    ${props => props.theme.breakpoints.up('md')} {
      font-size: ${irem(32)};
      line-height: ${irem(45)};
    }
  }
`

export const StyledCardSubTitle = styled(Grid)`
  font-size: ${props => props.theme.typography.pxToRem(18)};
  font-weight: 500;
  padding-bottom: ${props => props.theme.typography.pxToRem(20)};

  ${props => props.theme.breakpoints.up('md')} {
    font-size: ${props => props.theme.typography.pxToRem(24)};
  }

  &:hover {
    ${StyledChevronIcon} {
      color: ${props => props.theme.palette.secondary.main};
      transform: translateX(${props => props.theme.typography.pxToRem(4)});
    }
  }
`

export const StyledCardList = styled.ul`
  list-style: none;
  padding: 0;
`

export const StyledCardListItem = styled.li`
  font-size: ${props => props.theme.typography.pxToRem(15)};
  position: relative;
  padding-left: ${props => props.theme.typography.pxToRem(20)};
  margin-bottom: ${props => props.theme.typography.pxToRem(10)};

  & > span {
    color: ${props => props.theme.palette.secondary.main};
    font-size: ${props => props.theme.typography.pxToRem(15)};
    position: absolute;
    top: 0;
    left: 0;
  }
`

export const StyledCardText = styled(Typography)`
  font-size: ${props => props.theme.typography.pxToRem(15)};
  font-weight: normal;
  margin-bottom: ${props => props.theme.typography.pxToRem(15)};
`

export const BlackCardButton = styled(Button)`
  margin-bottom: ${props => props.theme.typography.pxToRem(20)};
`

export const GreenCardButton = styled(Button)`
  margin-bottom: ${props => props.theme.typography.pxToRem(20)};
  border-color: #517f34;
  color: #517f34;
`

export const StyledSubSection = styled(Grid)`
  margin-bottom: ${props => props.theme.typography.pxToRem(20)};
  padding: 0 ${props => props.theme.typography.pxToRem(20)};

  p,
  ul,
  ol {
    margin-bottom: 1em;

    &:last-child {
      margin-bottom: 0;
    }
  }

  p:last-child .MuiButton-root {
    margin-top: ${irem(10)};
  }
`

export const StyledTitleIcon = styled(Grid)`
  padding-right: ${props => props.theme.typography.pxToRem(10)};

  ${props => props.theme.breakpoints.up('md')} {
    img {
      height: 43px;
      width: auto;
    }
  }
`

export const StyledCardWrapper = styled(Grid)`
  background-color: ${props => props.theme.palette.common.white};
  box-shadow: 0 ${props => props.theme.typography.pxToRem(3)} ${props => props.theme.typography.pxToRem(15)} #0000001a;
  border: 2px solid #4d4d4d1f;
  border-radius: 0 0 4px 4px;
  border-top: 4px solid ${props => props.theme.palette.secondary.main};
  padding: ${props => props.theme.typography.pxToRem(50)} ${props => props.theme.typography.pxToRem(10)};
  width: 100%;
  font-size: ${irem(15)};
  line-height: ${irem(28)};

  ${props=> props.theme.breakpoints.up('md')} {
    font-size: ${irem(17)};
    padding: ${props => props.theme.typography.pxToRem(98)} ${props => props.theme.typography.pxToRem(60)} ${props => props.theme.typography.pxToRem(98)} ${props => props.theme.typography.pxToRem(60)};
  }

  ${StyledCardSection}, ${StyledCardTitle} {
    position: relative;

    + ${StyledCardSection} {
      margin-top: ${props => props.theme.typography.pxToRem(30)};

      &::before {
        top: -20px;
        left: 20px;
        right: 20px;
        position: absolute;
        display: block;
        content: "";
        height: 1px;
        background: #707070;
        opacity: 0.3;
      }

      ${props => props.theme.breakpoints.up('md')} {
        margin-top: 0;

        &::before {
          display: none;
        }
      }
    }
  }
`