import { gql } from '@apollo/client'
import React, { PropsWithChildren } from 'react'
import { FcpApplyNowCTA, FourColCta, OneColCta, TwoColCta } from '../../../fragments/cta'
import { FlexibleContent } from '../flexibleContent'
import { CtaFragment } from './__generated__/cta'

export type CtaProps = CtaFragment

export const Cta: React.FC<PropsWithChildren<CtaProps>> = ({ callToAction }) => {
  let cta = callToAction?.[0]?.callToAction?.[0]

  if (cta?.__typename === 'callToAction_linked_BlockType' && cta.cta?.[0]?.__typename === 'callToAction_default_Entry') {
    //@ts-ignore
    cta = cta.cta[0].callToAction?.[0]
  }

  if (!cta) {
    return null
  }

  return (
    <FlexibleContent row keepMargins  className="fc_cta">
      {cta.__typename === 'callToAction_oneColumn_BlockType' && (
        <OneColCta { ...cta } />
      )}
      {cta.__typename === 'callToAction_twoColumn_BlockType' && (
        <TwoColCta { ...cta } />
      )}
      {cta.__typename === 'callToAction_fourColumn_BlockType' && (
        <FourColCta { ...cta } />
      )}
      {cta.__typename === 'callToAction_applyNow_BlockType' && (
        <FcpApplyNowCTA { ...cta }/>
      )}
    </FlexibleContent>
  )
}


export const CTA_FRAGMENT = gql`
  fragment OneColCtaFragment on callToAction_oneColumn_BlockType {
    text
    subtext
    smallSubtext
    button {
      text
      url
      target
    }
  }

  fragment TwoColCtaFragment on callToAction_twoColumn_BlockType {
    left {
      ... on left_BlockType {
        text
        subtext
        button {
          text
          url
          target
        }
      }
    }
    right {
      ... on right_BlockType {
        text
        subtext
        button {
          text
          url
          target
        }
      }
    }
  }

  fragment FourColCtaFragment on callToAction_fourColumn_BlockType {
    ctaTitle
    ctaSubtitle
    topLeft {
      ... on topLeft_BlockType {
        icon
        subtext
        button {
          text
          url
          target
        }
      }
    }
    topRight {
      ... on topRight_BlockType {
        icon
        subtext
        button {
          text
          url
          target
        }
      }
    }
    bottomLeft {
      ... on bottomLeft_BlockType {
        icon
        subtext
        button {
          text
          url
          target
        }
      }
    }
    bottomRight {
      ... on bottomRight_BlockType {
        icon
        subtext
        button {
          text
          url
          target
        }
      }
    }
  }

  fragment FcpApplyNowFragment on callToAction_applyNow_BlockType {
    cta_apply_now {
      ... on applyNowCtaProducts_default_Entry {
        applyNowModule {
          ... on applyNowModule_BlockType {
            applyNowBlock {
              ... on applyNowBlock_leftColumn_BlockType {
                columnContent
              }
            }
            applyNowMiddleColumn {
              ... on applyNowMiddleColumn_middleColumn_BlockType {
                heading {
                  ... on heading_BlockType {
                    heading
                    icon
                    headingLink {
                      url
                      target
                    }
                  }
                }
                columnContent
              }
            }
            applyNowRightColumn {
              ... on applyNowRightColumn_rightColumn_BlockType {
                heading {
                  ... on heading_BlockType {
                    heading
                    icon
                    headingLink {
                      url
                      target
                    }
                  }
                }
                columnContent
              }
            }
          }
        }
      }
    }
  }
    
  fragment CtaFragment on flexibleContent_cta_BlockType {
    callToAction {
      ... on callToAction_BlockType {
        callToAction {
          ...OneColCtaFragment
          ...TwoColCtaFragment
          ...FourColCtaFragment
          ...FcpApplyNowFragment
          ... on callToAction_linked_BlockType {
            cta {
              ... on callToAction_default_Entry {
                callToAction {
                  ...OneColCtaFragment
                  ...TwoColCtaFragment
                  ...FourColCtaFragment
                }
              }
            }
          }
        }
      }
    }
  }
`
