import styled from 'styled-components'
import { Typography } from '@material-ui/core'
import { rem } from '../../utils/rem'
import { Disclaimer as DisclaimerBase } from '../disclaimer/disclaimer'

export const StyledFcpTableHeading = styled(Typography)`
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  letter-spacing: ${props => rem(1.5, props.theme)};
  color: ${props => props.theme.palette.primary.dark};
  text-transform: uppercase;
  text-align: center;
  opacity: 1;

  &&&& {
    margin: 0 0 ${props => rem(40, props.theme)};

    ${props => props.theme.breakpoints.up('sm')} {
      margin-bottom: ${props => rem(58, props.theme)};
    }

    ${props => props.theme.breakpoints.up('md')} {
      margin-bottom: ${props => rem(77, props.theme)};
    }
  }
`

export const StyledFcpDisclaimerBlock = styled.div`
  margin-top: ${props => rem(20, props.theme)};
  background: #f2f2f2;
  gap: 0;

  > div:first-child {
    background: #fff;
    padding-bottom: ${props => props.theme.typography.pxToRem(35)};
  }

  > div:nth-child(2) {
    padding-top: ${props => props.theme.typography.pxToRem(25)};
    padding-bottom: 0;
  }

  > div:nth-child(n+3) {
    padding-bottom: 0;
  }

  > div:last-child {
    padding-bottom: ${props => props.theme.typography.pxToRem(25)};
  }
`

export const StyledFcpDisclaimerSymbol = styled(Typography)`
  vertical-align: super;
  font-size: ${props => props.theme.typography.pxToRem(10)};
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  padding-right: ${props => props.theme.typography.pxToRem(3)};
`

export const StyledFcpDisclaimerBase = styled(DisclaimerBase)`
  & > p {
    display: inline;
  }
`
export const StyledFcpDisclaimerWrapper = styled(DisclaimerBase)`
  background: #f2f2f2;
  gap: 0;

  > div:first-child {
    background: #fff;
    padding-bottom: ${props => props.theme.typography.pxToRem(35)};
  }

  > div:nth-child(2) {
    padding-top: ${props => props.theme.typography.pxToRem(25)};
    padding-bottom: 0;
  }

  > div:nth-child(n+3) {
    padding-bottom: 0;
  }

  > div:last-child {
    padding-bottom: ${props => props.theme.typography.pxToRem(25)};
  }
`
export const StyledFcpDisclaimerTitle= styled(DisclaimerBase)`
  & > h5 {
    margin-left: ${props => props.theme.typography.pxToRem(20)};
  }
`

export const StyledFcpDiscliamerLink = styled.a`
  text-decoration: none;
  color: ${props => props.theme.palette.primary.main};
  vertical-align: super;
  font-size: 8px;
  font-weight: 500;
  padding-left: 3px;
`