import { gql } from '@apollo/client'
import React, { PropsWithChildren } from 'react'
import { IMAGE_FRAGMENT } from '../../../fragments/image'
import { html } from '../../../utils/htmlParser'
import { nonNull } from '../../../utils/nonNull'
import { OfferPromotionCarousel } from '../../OfferPromotionCarousel/OfferPromotionCarousel'
import { FlexibleContent } from '../flexibleContent'
import { FcpCarouselFragment as CarouselFragment } from './__generated__/carousel'

export type CarouselProps = CarouselFragment

export const Carousel: React.FC<PropsWithChildren<CarouselProps>> = ({ slides }) => {
  if (!slides) {
    return null
  }

  return (
    <FlexibleContent row>
      <OfferPromotionCarousel>
        {nonNull(slides).map((item) => (
          <OfferPromotionCarousel flickitySection key={item.uid} variant={'offer'}>
            {item.image?.[0]?.url && (
              <OfferPromotionCarousel image src={item.image[0].url} alt="" layout="fill" objectFit="cover" objectPosition="center" />
            )}
            <OfferPromotionCarousel contentSection>
              <OfferPromotionCarousel title>{item.slideTitle}</OfferPromotionCarousel>
              {item.subtitle && (
                <OfferPromotionCarousel description>
                  {html(item.subtitle)}
                </OfferPromotionCarousel>
              )}
              {item.button?.url && (
                <OfferPromotionCarousel href={item.button.url} cta primary buttonColor="black">
                  {item.button.text}
                </OfferPromotionCarousel>
              )}
            </OfferPromotionCarousel>
          </OfferPromotionCarousel>
        ))}
      </OfferPromotionCarousel>
    </FlexibleContent>
  )
}

export const FCP_CAROUSEL_FRAGMENT = gql`
  ${IMAGE_FRAGMENT}

  fragment FCPCarouselFragment on flexibleContent_carousel_BlockType {
    slides {
      ... on slides_BlockType {
        uid
        image {
          ...ImageFragment
        }
        slideTitle
        subtitle
        button {
          text
          url
        }
      }
    }
  }
`
