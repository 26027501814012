import { gql } from '@apollo/client'
import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'
import {
  RELATED_CONTENT_CAROUSEL_FRAGMENT,
  RelatedContentCarousel,
  RelatedContentCarouselProps,
} from '../../../fragments/relatedContentCarousel'
import { irem } from '../../../utils'
import { nonNull } from '../../../utils/nonNull'
import { StyledFlickityComponent } from '../../CardDesignCarousel/styledCardDesignCarousel'
import { FlexibleContentRow } from '../flexibleContentRow'
import { RelatedContentFragment } from './__generated__/relatedContent'

export type RelatedContentProps = RelatedContentFragment

const StyledRow = styled(FlexibleContentRow)`
  ${StyledFlickityComponent} {
    width: calc(100% + ${irem(20 * 2)});
    margin-left: ${irem(-20)};
    margin-right: ${irem(-20)};
    padding-bottom: ${irem(55)};

    ${props => props.theme.breakpoints.up('sm')} {
      width: calc(100% + ${irem(30 * 2)});
      margin-left: ${irem(-30)};
      margin-right: ${irem(-30)};
      padding-bottom: ${irem(84)};
    }

    ${props => props.theme.breakpoints.up('md')} {
      width: calc(100% + ${irem(32 * 2)});
      margin-left: ${irem(-32)};
      margin-right: ${irem(-32)};
    }
  }
`

export const RelatedContent: React.FC<PropsWithChildren<RelatedContentProps>> = ({ entries, columns, displayMode }) => {
  return (
    <StyledRow>
      {entries && (
        <RelatedContentCarousel columns={columns === '3' ? 3 : 4} items={nonNull(entries)} displayMode={displayMode as RelatedContentCarouselProps['displayMode']} />
      )}
    </StyledRow>
  )
}

export const RELATED_CONTENT_FRAGMENT = gql`
  ${RELATED_CONTENT_CAROUSEL_FRAGMENT}
  
  fragment RelatedContentFragment on flexibleContent_relatedContent_BlockType {
    columns
    displayMode
    entries {
      ...RelatedContentCarouselFragment
    }
  }
`