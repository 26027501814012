import { gql } from '@apollo/client'
import Grid from '@material-ui/core/Grid'
import React, { PropsWithChildren } from 'react'
import { FLEXIBLE_CONTENT_BASE_SPACING } from '../../../constants/flexibleContentBaseSpacing'
import { html } from '../../../utils/html'
import { FlexibleContent } from '../flexibleContent'
import { OneColumnRowFragment } from './__generated__/oneColumnRow'

export type OneColumnRowProps = OneColumnRowFragment

export const OneColumnRow: React.FC<PropsWithChildren<OneColumnRowProps>> = ({ column, narrow }) => {
  return (
    <FlexibleContent row>
      <Grid container spacing={FLEXIBLE_CONTENT_BASE_SPACING} justifyContent={'center'}>
        <Grid item xs={12} sm={narrow ? 10 : 12}>
          {column && html(column)}
        </Grid>
      </Grid>
    </FlexibleContent>
  )
}

export const ONE_COLUMN_ROW_FRAGMENT = gql`
  fragment OneColumnRowFragment on flexibleContent_oneColumnRow_BlockType {
    column
    narrow
  }
`