import { gql } from '@apollo/client'
import { Grid, GridSize, useMediaQuery } from '@material-ui/core'
import React, { PropsWithChildren, useCallback, useState } from 'react'
import styled from 'styled-components'
import { FLEXIBLE_CONTENT_BASE_SPACING } from '../../../constants/flexibleContentBaseSpacing'
import { theme } from '../../../theme'
import { html } from '../../../utils/html'
import { isNotNull, nonNull } from '../../../utils/nonNull'
import { TabHeader, TabsPropsFixed } from '../../tabs/tabHeader'
import { TabPanel } from '../../tabs/tabPanel'
import { TabWrapper } from '../../tabs/tabWrapper'
import { HeaderTabs } from '../../tabs/tabs'
import { FlexibleContent } from '../flexibleContent'
import { TabsFragment } from './__generated__/tabs'

export type TabsProps = TabsFragment

const StyledTabWrapper = styled(TabWrapper)`
  padding: 0;
`

export const Tabs: React.FC<PropsWithChildren<TabsProps>> = (data) => {
  const  tabData = data.tabBlock
  const [selectedTab, setSelectedTab] = useState(0)
  const onChange = useCallback(
    (_, newValue) => setSelectedTab(newValue),
    [setSelectedTab],
  ) as TabsPropsFixed['onChange']
  const mobile = useMediaQuery(theme.breakpoints.down('sm'))

  const goodTabs = tabData?.filter(isNotNull).filter((item) => Boolean(item.tabHeading && item.tabContent)) ?? []

  return (
    <FlexibleContent row keepMargins>
      <StyledTabWrapper>
        <TabHeader value={selectedTab} onChange={onChange} variant="scrollable">
          {
            goodTabs.map((item, i) => {
              return(
                <HeaderTabs key={i} label={item.tabHeading}/>
              )
            })
          }
        </TabHeader>
        {
          goodTabs.map((item, i ) => {
            return item.tabContent && item.tabContent.length > 0 ? (
              <TabPanel key={i} index={selectedTab} value={i}>
                <Grid container direction={mobile ? 'column' : 'row'} spacing={FLEXIBLE_CONTENT_BASE_SPACING}>
                  {nonNull(item.tabContent).map((column, i) => (
                    <Grid item key={i} sm={12} md={(12 / (item.tabContent?.length ?? 1)) as GridSize}>
                      {column.columnContent && html(column.columnContent)}
                    </Grid>
                  ))}
                </Grid>
              </TabPanel>
            ) : null
          })
        }
      </StyledTabWrapper>
    </FlexibleContent>
  )
}


export const TABS_FRAGMENT = gql`
  fragment TabsFragment on flexibleContent_tabs_BlockType {
    tabBlock {
      ... on tabBlock_BlockType {
        id
        tabHeading
        tabContent {
          ... on tabContent_column_BlockType {
            columnContent
          }
        }
      }
    }
  }
`