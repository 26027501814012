import { gql } from '@apollo/client'
import { Grid, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'
import React, { PropsWithChildren, useContext, useMemo } from 'react'
import styled from 'styled-components'
import { Disclaimer as DisclaimerBase } from '../components/disclaimer/disclaimer'
import { Link } from '../components/link/link'
import { Table } from '../components/table/Table'
import { DisclaimerContext } from '../context/disclaimerContext'
import { Maybe } from '../gql'
import { irem, rem } from '../utils'
import { html } from '../utils/htmlParser'
import { isNotNull, nonNull } from '../utils/nonNull'
import {
  DisclaimersFragment,
  RateTableApiRateFragment,
  RateTableCustomRateFragment,
  RatesTableFragment,
} from './__generated__/ratesTable'

export type RatesTableProps = RatesTableFragment

type OptionalColumns = {
  repaymentType: boolean
  purpose: boolean
  lvr: boolean
  productTier: boolean
  productBonusRate: boolean
  comparisonRate: boolean
  optionalColumnName?: Maybe<string>
  optionalColumnNameInterest?: Maybe<string>
  optionalColumnNameComparisonRate?: Maybe<string>
}

export const RatesTable: React.FC<PropsWithChildren<RatesTableProps>> = (table) => {
  if (table.__typename !== 'rateTables_default_Entry') {
    return null
  }

  const disclaimers = useMemo(() => {
    const disclaimers = new Set<string>()

    if (table.rateTablePicker) {
      for (const rate of table.rateTablePicker) {
        if (rate && rate.__typename === 'productRatesCustom_default_Entry') {
          for (const disclaimer of rate?.disclaimer_entry_field ?? []) {
            if (disclaimer && (disclaimer.__typename === 'homeLoanDisclaimers_default_Entry' || disclaimer.__typename === 'personalloandisclaimers_default_Entry' || disclaimer.__typename === 'customDisclaimers_default_Entry') && disclaimer.disclaimerText) {
              disclaimers.add(disclaimer.disclaimerText)
            }
          }
        }
      }
    }

    return disclaimers
  }, [table])

  const columns: OptionalColumns = {
    comparisonRate: table.optionalTableColumns?.[0]?.column_comparisonRate ?? false,
    lvr: table.optionalTableColumns?.[0]?.column_lvr ?? false,
    productBonusRate: table.optionalTableColumns?.[0]?.column_productBonusRate ?? false,
    productTier: table.optionalTableColumns?.[0]?.column_productTier ?? false,
    purpose: table.optionalTableColumns?.[0]?.column_purpose ?? false,
    repaymentType: table.optionalTableColumns?.[0]?.column_repaymentType ?? false,
    optionalColumnName: table.optionalTableColumns?.[0]?.optionalColumnNameBonus,
    optionalColumnNameInterest: table.optionalTableColumns?.[0]?.optionalColumnNameInterest,
    optionalColumnNameComparisonRate: table.optionalTableColumns?.[0]?.optionalColumnNameComparisonRate,
  }

  return (
    table.rateTablePicker && table.rateTablePicker?.length > 0 ?
      <StyledTableWrapper container justifyContent={'center'}>
        {table.tableTitle && (
          <Grid item xs={12}>
            <StyledTableHeading>{table.tableTitle}</StyledTableHeading>
          </Grid>
        )}
        <Grid item xs={12} sm={10} md={8}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Product Name</TableCell>
                {columns.productTier && (
                  <TableCell>Tier</TableCell>
                )}
                {columns.optionalColumnNameInterest ? 
                  <TableCell>{columns.optionalColumnNameInterest}</TableCell> : <TableCell>Interest Rate</TableCell>
                }
                {columns.lvr && (
                  <TableCell>LVR</TableCell>
                )}
                {columns.comparisonRate && (
                  columns.optionalColumnNameComparisonRate ? <TableCell>{columns.optionalColumnNameComparisonRate}</TableCell> : <TableCell>Comparison rate</TableCell>
                )}
                {columns.productBonusRate && (
                  columns.optionalColumnName ? <TableCell>{columns.optionalColumnName}</TableCell> : <TableCell>Bonus + Rate</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {table.rateTablePicker && nonNull(table.rateTablePicker).map((item, index) => {
                if (item) {
                  switch (item.__typename) {
                  case 'productRatesCustom_default_Entry':
                    return <CustomRateTableRow key={index} item={item} columns={columns} />
                  case 'rates_default_Entry':
                    return <ApiRateTableRow key={index} item={item} columns={columns} />
                  }
                }
              })}
            </TableBody>
          </Table>
          {disclaimers.size > 0 && (
            <StyledDisclaimerBlock>
              {[ ...disclaimers.values() ].map((item, index) => (
                <StyledDisclaimerBase key={index} textBlock>
                  {html(item)}
                </StyledDisclaimerBase>
              ))}
            </StyledDisclaimerBlock>
          )}
        </Grid>
      </StyledTableWrapper>
      : null
  )
}

const formatPercentage = (number?: string | null) => Number.isNaN(Number(number)) ? number : `${Number(number).toFixed(2)}% p.a.`

type CustomRateTableRowProps = {
  columns: OptionalColumns
  item: RateTableCustomRateFragment
}

const CustomRateTableRow: React.VFC<CustomRateTableRowProps> = ({ columns, item }) => item.__typename === 'productRatesCustom_default_Entry' ? (
  <TableRow data-custom>
    <TableCell>
      {item.title}
    </TableCell>
    {columns.productTier && (
      <TableCell align={'left'}>
        {item.productTier}
      </TableCell>
    )}
    <TableCell>
      {formatPercentage(item.productPrimaryRate)}
    </TableCell>
    {columns.lvr && (
      <TableCell align={'left'}>
        {item.productLvr}
      </TableCell>
    )}
    {columns.comparisonRate && (
      <TableCell align={'left'}>
        {formatPercentage(item.productComparisonRate)}
      </TableCell>
    )}
    {columns.productBonusRate && (
      <TableCell align={'left'}>
        {formatPercentage(item.productBonusRate)}
      </TableCell>
    )}
  </TableRow>
) : null

type ApiRateTableRowProps = {
  columns: OptionalColumns
  item: RateTableApiRateFragment
}

const ApiRateTableRow: React.VFC<ApiRateTableRowProps> = ({ columns, item }) => {
  const { pageId } = useContext(DisclaimerContext)

  return item.__typename === 'rates_default_Entry' ? (
    <TableRow data-api>
      <TableCell>
        {item.title}
        {columns.repaymentType && item?.rateRepaymentType && item.rateRepaymentType in REPAYMENT_TYPES && ` - ${REPAYMENT_TYPES[item.rateRepaymentType]}`}
        {columns.purpose && item?.ratePurpose && item.ratePurpose in PURPOSE_TYPES && ` - ${PURPOSE_TYPES[item.ratePurpose]}`}
        {item.rateLvrMaximum && <span> &le;{item.rateLvrMaximum}% LVR </span>}
        <Link href={`#disclaimer_block_${pageId}`} passHref>
          <StyledDiscliamerLink>
            <DisclaimerIcons disclaimers={[ ...(item.homeLoanDisclaimers ?? []), ...(item.personalLoanDisclaimers ?? []) ]} />
          </StyledDiscliamerLink>
        </Link>
      </TableCell>
      {columns.productTier && (
        <TableCell />
      )}
      <TableCell>
        {formatPercentage(item.productPrimaryRate)}
      </TableCell>
      {columns.lvr && item.rateLvrMinimum && item.rateLvrMaximum && (
        <TableCell align={'left'}>
          {`${item.rateLvrMinimum}.00% - ${item.rateLvrMaximum}.00%`}
        </TableCell>
      )}
      {columns.comparisonRate && (
        <TableCell align={'left'}>
          {formatPercentage(item.productComparisonRate)}
        </TableCell>
      )}
      {columns.productBonusRate && (
        <TableCell />
      )}
    </TableRow>
  ) : null
}

export const DISCLAIMERS_FRAGMENT = gql`
  fragment DisclaimersFragment on EntryInterface {
    ... on homeLoanDisclaimers_default_Entry {
      disclaimerId
      disclaimerText
    }
    ... on personalloandisclaimers_default_Entry {
      disclaimerId
      disclaimerText
    }
    ... on customDisclaimers_default_Entry {
      disclaimerText
    }
  }
`

export const RATES_TABLE_FRAGMENT = gql`
  ${DISCLAIMERS_FRAGMENT}
  
  fragment RateTableApiRateFragment on EntryInterface {
    ... on rates_default_Entry {
      title
      productPrimaryRate
      productComparisonRate
      rateRepaymentType
      ratePurpose
      rateLvrMinimum
      rateLvrMaximum
      homeLoanDisclaimers {
        ...DisclaimersFragment
      }
      personalLoanDisclaimers {
        ...DisclaimersFragment
      }
    }
  }
  
  fragment RateTableCustomRateFragment on EntryInterface {
    ... on productRatesCustom_default_Entry {
      title
      productPrimaryRate
      productComparisonRate
      productBonusRate
      productTier
      productLvr
      disclaimer_entry_field {
        ...DisclaimersFragment
      }
    }
  }
  
  fragment RatesTableFragment on EntryInterface {
    ... on rateTables_default_Entry {
      uid
      tableTitle
      optionalTableColumns {
        ... on optionalTableColumns_BlockType {
          column_repaymentType
          column_purpose
          column_lvr
          column_productTier
          column_productBonusRate
          column_comparisonRate
          optionalColumnNameBonus
          optionalColumnNameInterest
          optionalColumnNameComparisonRate
        }
      }
      rateTablePicker {
        ...RateTableApiRateFragment
        ...RateTableCustomRateFragment
      }
    }
  }
`

export const StyledTableHeading = styled(Typography)`
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  letter-spacing: ${props => rem(1.5, props.theme)};
  color: ${props => props.theme.palette.primary.dark};
  text-transform: uppercase;
  text-align: center;
  opacity: 1;

  &&&& {
    margin-bottom: ${props => rem(56, props.theme)};

    ${props => props.theme.breakpoints.up('sm')} {
      margin-bottom: ${props => rem(87, props.theme)};
    }
  }
`

export const StyledTableWrapper = styled(Grid)`
  margin-top: ${props => rem(56, props.theme)};
  margin-bottom: ${props => rem(56, props.theme)};

  ${props => props.theme.breakpoints.up('sm')} {
    margin-bottom: ${props => rem(87, props.theme)};
  }

  table {
    white-space: nowrap;

    ${props => props.theme.breakpoints.up('md')} {
      white-space: normal;
    }

    > thead > tr > th {
      -webkit-text-size-adjust: none;
      font-size: 14px;

      ${props => props.theme.breakpoints.up('sm')} {
        -webkit-text-size-adjust: none;
        font-size: ${irem(17)};
      }
    }

    > tbody > tr > td {
      -webkit-text-size-adjust: none;
      padding: ${irem(5)} ${irem(10)};

      ${props => props.theme.breakpoints.up('md')} {
        -webkit-text-size-adjust: none;
        padding: ${irem(16)} ${irem(16)} ${irem(9)};
        font-size: ${irem(17)} !important;
      }

      &:first-child {
        font-weight: ${props => props.theme.typography.fontWeightMedium};

        ${props => props.theme.breakpoints.up('md')} {
          font-weight: ${props => props.theme.typography.fontWeightRegular};
        }
      }
    }
  }
`
export const StyledDisclaimerBlock = styled.div`
  margin: ${props => rem(20, props.theme)} ${props => rem(10, props.theme)} 0 ${props => rem(10, props.theme)};
  background: #f2f2f2;

  ${props => props.theme.breakpoints.up('sm')} {
    margin: 0;
  }

  > div {
    padding-bottom: 0;
  }

  > div:last-child {
    padding-bottom: ${props => rem(14, props.theme)};
  }
`
export const StyledDisclaimerSymbol = styled(Typography)`
  vertical-align: middle;
  font-size: ${props => props.theme.typography.pxToRem(10)};
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  padding-right: ${props => props.theme.typography.pxToRem(3)};
`
export const StyledDisclaimerBase = styled(DisclaimerBase)`
  & > p {
    display: inline;
  }
`
export const StyledDiscliamerLink = styled.a`
  text-decoration: none;
  color: ${props => props.theme.palette.primary.main};
  vertical-align: super;
  font-size: 0.65em;
  font-weight: 500;
  padding-left: 3px;
`
export const StyledIcon = styled.span`
  & + &::before {
    content: ' ';
  }
`

export const DisclaimerIcons: React.VFC<{ disclaimers: Maybe<DisclaimersFragment>[] }> = ({ disclaimers }) => {
  const { iconMap } = useContext(DisclaimerContext)

  const icons = nonNull(disclaimers).map(d => {
    if ((d.__typename === 'homeLoanDisclaimers_default_Entry' || d.__typename === 'personalloandisclaimers_default_Entry') && d.disclaimerId && iconMap.has(d.disclaimerId)) {
      return (
        <StyledIcon key={d.disclaimerId}>
          {iconMap.get(d.disclaimerId)}
        </StyledIcon>
      )
    }

    return null
  }).filter(isNotNull)

  return (
    <>
      {icons}
    </>
  )
}

export const REPAYMENT_TYPES = {
  'IO': 'Interest Only',
  'P&I': 'Principal & Interest',
} as const

export const PURPOSE_TYPES = {
  'I': 'Investment',
  'OO': 'Owner Occupied',
} as const