import React, { PropsWithChildren } from 'react'
import { Link } from '../link/link'
import {
  AuthorCategory,
  AuthorInformationWrapper,
  AuthorLastUpdatedDate,
  AuthorSeparator,
  StyledContact,
  StyledContactContainer,
  StyledDescription,
  StyledEducationCredentials,
  StyledPeopleModuleContainer,
  StyledPeopleModuleGroupProfile,
  StyledPeopleModuleGroupUserInfo,
  StyledPeopleModuleHeading,
  StyledPeopleModuleName,
  StyledPeopleModuleTitle,
  StyledVerticalContactPhoneContainer,
  StyleImageProfile,
  StyleImageProfileContainer,
} from './StyledPeopleModule'

// Container
export type PeopleModuleContainerProps = {
  layout?: 'vertical' | 'horizontal'
  variant?: 'people' | 'author'
} & React.ComponentProps<typeof StyledPeopleModuleContainer>

export const PeopleModuleContainer: React.FC<PropsWithChildren<PeopleModuleContainerProps>> = ({ layout, variant, children, ...props }) => {
  if (!children) {
    return null
  }

  const [avatar, heading, author, contactSection, description] = React.Children.toArray(children)
  
  if (layout === 'vertical') {
    return (
      <StyledPeopleModuleContainer $variant={variant} {...props}>
        <StyledPeopleModuleGroupProfile>
          {avatar}
          {heading}
        </StyledPeopleModuleGroupProfile>
        <StyledPeopleModuleGroupUserInfo>
          {author}
          {contactSection}
          {description}
        </StyledPeopleModuleGroupUserInfo>
      </StyledPeopleModuleContainer>
    )
  }

  return (
    <StyledPeopleModuleContainer $variant={variant} {...props}>
      <StyledPeopleModuleGroupProfile>
        {avatar}
      </StyledPeopleModuleGroupProfile>
      <StyledPeopleModuleGroupUserInfo>
        {heading}
        {author}
        {contactSection}
        {description}
      </StyledPeopleModuleGroupUserInfo>
    </StyledPeopleModuleContainer>
  )
}

// Avatar
export type ImageProfileProps = {
  src?: string
} & React.ComponentProps<typeof StyleImageProfileContainer>

export const ImageProfile: React.FC<PropsWithChildren<ImageProfileProps>> = ({ src, ...props }) => {
  return (
    <StyleImageProfileContainer {...props}>
      {src && <StyleImageProfile src={src} alt="avatar" layout="fill" objectFit="cover" objectPosition="center center" />}
    </StyleImageProfileContainer>
  )
}

// Heading section
export type PeopleModuleHeadingProps = {
  //
} & React.ComponentProps<typeof StyledPeopleModuleHeading>

export const PeopleModuleHeading: React.FC<PropsWithChildren<PeopleModuleHeadingProps>> = (props) => (
  <StyledPeopleModuleHeading { ...props}>
    {props.children}
  </StyledPeopleModuleHeading>
)

// Name
export type PeopleModuleNameProps = {
  layout?: 'vertical' | 'horizontal'
  type?: 'people' | 'author'
} & React.ComponentProps<typeof StyledPeopleModuleName>

export const PeopleModuleName: React.FC<PropsWithChildren<PeopleModuleNameProps>> = ({ layout, type, ...props }) => (
  <StyledPeopleModuleName variant={layout === 'horizontal' ? 'h2' : 'body1'} $type={type} {...props}>
    {props.children}
  </StyledPeopleModuleName>
)

// Title
export type PeopleModuleTitleProps = {
  type?: 'people' | 'author'
} & React.ComponentProps<typeof StyledPeopleModuleTitle>

export const PeopleModuleTitle: React.FC<PropsWithChildren<PeopleModuleNameProps>> = ({ type, ...props }) => (
  <StyledPeopleModuleTitle variant="h5" $type={type} { ...props}>
    {props.children}
  </StyledPeopleModuleTitle>
)

// Education Credentials
export type EducationCredentialsProps = {
  //
} & React.ComponentProps<typeof StyledEducationCredentials>

export const EducationCredentials: React.FC<PropsWithChildren<EducationCredentialsProps>> = (props) => (
  <StyledEducationCredentials { ...props}>
    {props.children}
  </StyledEducationCredentials>
)

// User Contacts (including: phone, mobile, and email)
export type PeopleContactContainerProps = {
  layout: 'vertical' | 'horizontal'
}  & React.ComponentProps<typeof StyledContactContainer>

export const PeopleContactContainer: React.FC<PropsWithChildren<PeopleContactContainerProps>> = ({ children, layout, ...props }) => {
  if (!children) {
    return null
  }

  if (layout === 'vertical') {
    const [phone, mobile, email] = React.Children.toArray(children)

    return (
      <StyledContactContainer {...props}>
        <StyledVerticalContactPhoneContainer>
          {phone}
          {mobile}
        </StyledVerticalContactPhoneContainer>
        {email}
      </StyledContactContainer>
    )
  }

  return (
    <StyledContactContainer {...props}>
      {children}
    </StyledContactContainer>
  )
}

export type PeopleContactProps = {
  contactType?: 'phone' | 'mobile' | 'email'
  href?: string
} & React.ComponentProps<typeof StyledContact>

export const PeopleContact: React.FC<PropsWithChildren<PeopleContactProps>> = ({ contactType, href, ...props }) => {
  if (!href || !props.children) {
    return null
  }

  if (contactType === 'phone') {
    return (
      <StyledContact { ...props}>
        <a href={'tel:' + href}>
          <span>p: </span>
          <span>{props.children}</span>
        </a>
      </StyledContact>
    )
  }

  if (contactType === 'mobile') {
    return (
      <StyledContact { ...props}>
        <a href={'tel:' + href}>
          <span>m: </span>
          <span>{props.children}</span>
        </a>
      </StyledContact>
    )
  }

  return (
    <StyledContact { ...props}>
      <a href={'mailto:' + href}>
        <span>e: </span>
        <span>{props.children}</span>
      </a>
    </StyledContact>
  )
}

// Profile description
export type PeopleModuleDescriptionProps = {
  //
} & React.ComponentProps<typeof StyledDescription>

export const PeopleModuleDescription: React.FC<PropsWithChildren<PeopleModuleDescriptionProps>> = ({ ...props }) => {
  return (<StyledDescription { ...props} variant="body2">{props.children}</StyledDescription>)
}

// Author information
export type AuthorInformationProps = {
  href?: string
} & React.ComponentProps<typeof AuthorInformationWrapper>

export const AuthorInformation: React.FC<PropsWithChildren<AuthorInformationProps>> = ({ href, children, ...props }) => {
  if (!children) {
    return null
  }

  const [date, category] = React.Children.toArray(children)

  return (
    <AuthorInformationWrapper {...props}>
      <AuthorLastUpdatedDate variant="body1">{date}</AuthorLastUpdatedDate>
      {category && (
        <>
          <AuthorSeparator> | </AuthorSeparator>
          <Link href= {href+'#filter' || '#'} passHref>
            <AuthorCategory>{category}</AuthorCategory>
          </Link>
        </>
      )}
    </AuthorInformationWrapper>
  )
}
