import { gql } from '@apollo/client'
import React, { PropsWithChildren } from 'react'
import { isWidgetWorksFrame, WidgetWorksFrame } from '../../../utils/widgetWorks'
import { FlexibleContent } from '../flexibleContent'

export type iFrameProps = {
  iframe: WidgetWorksFrame | 'forex'
}

export const iFrame: React.FC<PropsWithChildren<iFrameProps>> = ({ iframe }) => {
  let frame = <></>

  if (isWidgetWorksFrame(iframe)) {
    frame = (
      <WidgetWorksFrame frame={iframe} />
    )
  }

  if (iframe === 'forex') {
    frame = (
      <iframe title="Foreign Exchange" src="https://gpm.westernunion.com/au/AU/RT/02156-FI" height="2900" width="100%" scrolling="no" marginHeight={0} marginWidth={0} frameBorder={0} style={{ border: 'none', overflow: 'hidden' }} />
    )
  }

  return (
    <FlexibleContent row>
      {frame}
    </FlexibleContent>
  )
}

export const IFRAME_FRAGMENT = gql`
  fragment iFrameFragment on flexibleContent_iframe_BlockType {
    iframe
  }
`