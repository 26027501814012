import { gql } from '@apollo/client'
import Grid from '@material-ui/core/Grid'
import React, { PropsWithChildren } from 'react'
import { FLEXIBLE_CONTENT_BASE_SPACING } from '../../../constants/flexibleContentBaseSpacing'
import { IMAGE_FRAGMENT } from '../../../fragments/image'
import { html } from '../../../utils/html'
import { isNotNull } from '../../../utils/nonNull'
import { PeopleModule } from '../../peoplemodule/PeopleModule'
import { FlexibleContent } from '../flexibleContent'
import { PeopleFragment } from './__generated__/people'

export type PeopleProps = PeopleFragment

export const People: React.FC<PropsWithChildren<PeopleProps>> = (data) => {
  const teamMemberData = data.teamMember
  const teamMemberLayoutTwoCol = data.people_module_layout 

  const peopleModuleData = teamMemberData?.map(item => {
    if (item && item.__typename === 'peopleChannel_default_Entry'){
      const name = item.teamAuthorName?.[0]
      const bio = item.teamMemberBio?.[0]

      return name && bio ? (
        {
          firstName: name.peopleFirstName,
          lastName: name.peopleLastName,
          title: name.peopleDesignation,
          avatar: name.peopleImage?.[0]?.url,
          education: name.education,
          phone: bio.teamMemberPhone,
          mobile: bio.teamMemberMobile,
          email: bio.teamMemberEmail,
          description: bio.teamMemberBio,
        }
      ) : null
    }
  }) ?? []

  const person = peopleModuleData[0]

  if (!person) {
    return null
  }

  return (
    <> 
      {
        teamMemberLayoutTwoCol ? (
          <FlexibleContent row>
            <Grid container spacing={FLEXIBLE_CONTENT_BASE_SPACING} justifyContent={'space-between'} alignItems={'flex-start'}>
              { peopleModuleData && peopleModuleData.map((data, i) =>(
                <Grid item key={i} xs={12} sm={12} md={6}>
                  <PeopleModule variant="people">
                    <PeopleModule avatar src={data?.avatar} />
                    <PeopleModule heading>
                      <PeopleModule name>{`${data?.firstName} ${data?.lastName}`}</PeopleModule>
                      <PeopleModule title>{data?.title}</PeopleModule>
                      {isNotNull(data?.education) &&
                <PeopleModule educationCredentials>{data?.education}</PeopleModule>
                      }
                    </PeopleModule>
                    {(isNotNull(data?.phone) || isNotNull(data?.mobile) || isNotNull(data?.email)) &&
              <PeopleModule contactSection>
                <PeopleModule contact contactType="phone" href={data?.phone}>{data?.phone}</PeopleModule>
                <PeopleModule contact contactType="mobile" href={data?.mobile}>{data?.mobile}</PeopleModule>
                <PeopleModule contact contactType="email" href={data?.email}>{data?.email}</PeopleModule>
              </PeopleModule>
                    }
                    {isNotNull(data?.description) && (
                      <PeopleModule description>{html(data?.description as string)}</PeopleModule>
                    )}
                  </PeopleModule>
                </Grid>
              ))}
            </Grid>
          </FlexibleContent>
        ) :
          (
            <FlexibleContent row>
              <Grid container spacing={FLEXIBLE_CONTENT_BASE_SPACING} justifyContent={'center'}>
                <Grid item xs={12} sm={10} md={8}>
                  <PeopleModule variant="people">
                    <PeopleModule avatar src={person.avatar} />
                    <PeopleModule heading>
                      <PeopleModule name>{`${person.firstName} ${person.lastName}`}</PeopleModule>
                      <PeopleModule title>{person.title}</PeopleModule>
                      {isNotNull(person.education) &&
                <PeopleModule educationCredentials>{person.education}</PeopleModule>
                      }
                    </PeopleModule>
                    {(isNotNull(person.phone) || isNotNull(person.mobile) || isNotNull(person.email)) &&
              <PeopleModule contactSection>
                <PeopleModule contact contactType="phone" href={person.phone}>{person.phone}</PeopleModule>
                <PeopleModule contact contactType="mobile" href={person.mobile}>{person.mobile}</PeopleModule>
                <PeopleModule contact contactType="email" href={person.email}>{person.email}</PeopleModule>
              </PeopleModule>
                    }
                    {isNotNull(person.description) && (
                      <PeopleModule description>{html(person.description)}</PeopleModule>
                    )}
                  </PeopleModule>
                </Grid>
              </Grid>
            </FlexibleContent>
          )
      }
    </>
  )
}

export const PEOPLE_FRAGMENT = gql`
  ${IMAGE_FRAGMENT}
  fragment PeopleFragment on flexibleContent_peopleModule_BlockType {
    people_module_layout
    teamMember {
      ... on peopleChannel_default_Entry {
        teamAuthorName {
          ... on teamAuthorName_BlockType {
            peopleImage {
              ...ImageFragment
            }
            peopleFirstName
            peopleDesignation
            peopleLastName
            education
          }
        }
        teamMemberBio {
          ... on teamMemberBio_BlockType {
            teamMemberEmail
            teamMemberMobile
            teamMemberPhone
            teamMemberBio
          }
        }
      }
    }
  }
`