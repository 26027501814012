import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import React, { PropsWithChildren, useMemo } from 'react'
import {
  AuthorInformation,
  AuthorInformationProps,
  EducationCredentials,
  EducationCredentialsProps,
  ImageProfile,
  ImageProfileProps,
  PeopleContact,
  PeopleContactContainer,
  PeopleContactContainerProps,
  PeopleContactProps,
  PeopleModuleContainer,
  PeopleModuleContainerProps,
  PeopleModuleDescription,
  PeopleModuleDescriptionProps,
  PeopleModuleHeading,
  PeopleModuleHeadingProps,
  PeopleModuleName,
  PeopleModuleNameProps,
  PeopleModuleTitle,
  PeopleModuleTitleProps,
} from './PeopleModuleElement'

export type PeopleModuleProps = ({variant?: 'people' | 'author' ; avatar?: boolean; heading?: boolean; name?: boolean; title?: boolean; educationCredentials?: boolean; contactSection?: boolean; contact?: boolean; description?: boolean; authorInfo?: boolean }) & (
  // Avatar
  | ({ avatar: true } & ImageProfileProps)
  // Heading section
  | ({ heading: true } & PeopleModuleHeadingProps)
  // People name
  | ({ name: true } & PeopleModuleNameProps)
  // Title
  | ({ title: true } & PeopleModuleTitleProps)
  // Education credentials
  | ({ educationCredentials: true } & EducationCredentialsProps)
  // Contact section
  | ({ contactSection: true } & PeopleContactContainerProps)
  // Contact (phone, mobile, email)
  | ({ contact: true } & PeopleContactProps)
  // Description
  | ({ description: true } & PeopleModuleDescriptionProps)
  // Author information
  | ({ authorInfo: true } & AuthorInformationProps)
  // People module container
  | PeopleModuleContainerProps
)

export const PeopleModule: React.FC<PropsWithChildren<PeopleModuleProps>> = ({ variant, avatar, heading, name, title, educationCredentials, contactSection, contact, description, authorInfo, ...props }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))

  // Get layout variant
  const layout = useMemo(() => {
    return isMobile ? 'vertical' : 'horizontal'
  }, [isMobile])


  if (heading) {
    return (
      <PeopleModuleHeading {...props}>
        {props.children}
      </PeopleModuleHeading>
    )
  }

  if (avatar) {
    return (
      <ImageProfile {...props} />
    )
  }

  if (name) {
    return (
      <PeopleModuleName {...props} layout={layout}>
        {props.children}
      </PeopleModuleName>
    )
  }

  if (title) {
    return (
      <PeopleModuleTitle {...props}>
        {props.children}
      </PeopleModuleTitle>
    )
  }

  if (educationCredentials) {
    return (
      <EducationCredentials {...props}>
        {props.children}
      </EducationCredentials>
    )
  }

  if (contactSection) {
    return (
      <PeopleContactContainer {...props} layout={layout}>
        {props.children}
      </PeopleContactContainer>
    )
  }

  if (contact) {
    return (
      <PeopleContact {...props}>{props.children}</PeopleContact>
    )
  }

  if (description) {
    return (
      <PeopleModuleDescription {...props}>{props.children}</PeopleModuleDescription>
    )
  }

  if (authorInfo) {
    return (
      <AuthorInformation {...props}>
        {props.children}
      </AuthorInformation>
    )
  }

  return (
    <PeopleModuleContainer {...props} variant={variant} layout={layout}>
      {props.children}
    </PeopleModuleContainer>
  )
}
