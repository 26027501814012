/* eslint-disable unicorn/filename-case */
import { gql } from '@apollo/client'
import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'
import { Accordion } from '../../../components/accordion/accordion'
import { rem } from '../../../utils'
import { html } from '../../../utils/htmlParser'
import { NewsFaqFragment } from './__generated__/accordionFAQs'

export type NewsFAQProps = {
  data: NewsFaqFragment[]
}

const StyledAccordionWrapper = styled(Accordion)`
  ${props => props.theme.breakpoints.up('md')} {
    margin-bottom: ${props => rem(74, props.theme)};
  }
`

const StyledAccordion = styled(Accordion)`
  margin-bottom: ${props => props.theme.typography.pxToRem(24)};
`

export const FAQAccordions: React.FC<PropsWithChildren<NewsFAQProps>> = ({ data }) => (
  <StyledAccordionWrapper>
    {data.map((FAQ) => {
      
      return FAQ.__typename !== 'faqs_default_Entry' ? null : (
        <StyledAccordion panel key={FAQ.id}>
          <Accordion title>{FAQ.title}</Accordion>
          <Accordion content>{html(FAQ.faqText ?? '')}</Accordion>
        </StyledAccordion>
      )
    })}
  </StyledAccordionWrapper>
)

export const NEWS_FAQS_FRAGMENT = gql`
  fragment NewsFAQFragment on faqs_default_Entry {
    id
    title
    faqText
  }
`
