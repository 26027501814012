import { gql } from '@apollo/client'
import Grid from '@material-ui/core/Grid'
import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'
import { FLEXIBLE_CONTENT_BASE_SPACING } from '../../../constants/flexibleContentBaseSpacing'
import { rem } from '../../../utils'
import { html } from '../../../utils/htmlParser'
import { nonNull } from '../../../utils/nonNull'
import { FeaturesList } from '../../FeaturesList/FeaturesList'
import { StyledFeaturesListContainer } from '../../FeaturesList/styledFeaturesList'
import { FlexibleContentRow } from '../flexibleContentRow'
import { FeatureGridFragment } from './__generated__/featureGrid'

export type FeatureGridProps = FeatureGridFragment

const StyledFlexibleContentRow = styled(FlexibleContentRow)`
  ${StyledFeaturesListContainer} {
    margin-top: ${props => rem(-36, props.theme)};
    padding-left: 0;
  }
`

export const FeatureGrid: React.FC<PropsWithChildren<FeatureGridProps>> = ({ cards, body }) => {
  return (
    <StyledFlexibleContentRow>
      <Grid container spacing={FLEXIBLE_CONTENT_BASE_SPACING}>
        <Grid item xs={12} sm={12} md={6}>
          {body && html(body)}
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          {cards && (
            <FeaturesList>
              {nonNull(cards).map((card) => {
                if (!card.cardTitle) {
                  return null
                }

                const hasAsterisk = card.cardTitle[card.cardTitle.length - 1] === '*'

                return (
                  <FeaturesList itemWrapper key={card.uid}>
                    <FeaturesList icon iconCode={card.icon} />
                    <FeaturesList section>
                      <FeaturesList title asterisk={hasAsterisk}>{hasAsterisk ? card.cardTitle.slice(0, -2) : card.cardTitle}</FeaturesList>
                      {card.body && (
                        <FeaturesList description>{html(card.body)}</FeaturesList>
                      )}
                    </FeaturesList>
                  </FeaturesList>
                )
              })}
            </FeaturesList>
          )}
        </Grid>
      </Grid>
    </StyledFlexibleContentRow>
  )
}

export const FEATURE_GRID_FRAGMENT = gql`
  fragment FeatureGridFragment on flexibleContent_featureGrid_BlockType {
    body
    cards {
      ... on cards_BlockType {
        uid
        icon
        cardTitle
        body
      }
    }
  }
`
