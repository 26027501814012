import Link from 'next/link'
import React from 'react'
import styled from 'styled-components'
import { AccountsCard } from '../components/accountsCard/accountsCard'
import { ContactCTA } from '../components/contactcta/contactcta'
import {
  FcpApplyNowFragment,
  FourColCtaFragment,
  OneColCtaFragment,
  TwoColCtaFragment,
} from '../components/flexibleContent/fragments/__generated__/cta'
import { SimpleCta } from '../components/simplecta/SimpleCta'
import { ToolCta } from '../components/toolcta/ToolCta'
import { irem } from '../utils'
import { html } from '../utils/htmlParser'
import { isNotNull, nonNull } from '../utils/nonNull'

export const StyledApplyNowLink = styled.a`
  color: ${props => props.theme.palette.text.primary};
  text-decoration: none;
  padding-left: ${irem(10)};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  font-size: ${irem(20)};

  ${props => props.theme.breakpoints.up('md')} {
    font-size: ${irem(24)};
  }

  &:hover {
    &::after {
      color: ${props => props.theme.palette.secondary.main};
      transform: translateX(${props => props.theme.typography.pxToRem(4)});
    }
  }
`

export const OneColCta: React.VFC<OneColCtaFragment> = ({ button, text, subtext, smallSubtext }) => (
  <SimpleCta>
    <SimpleCta section>
      {text && <SimpleCta title>{text}</SimpleCta>}
      {subtext && <SimpleCta subtitle>{subtext}</SimpleCta>}
      {smallSubtext && <SimpleCta content>{smallSubtext}</SimpleCta>}
    </SimpleCta>
    {button && (
      <SimpleCta cta padding={subtext? true : (smallSubtext ? true : false)} href={button.url || '#'} target={ button.target === '_blank' ? '_blank' : '_self'}>{button.text}</SimpleCta>
    )}
  </SimpleCta>
)

export const TwoColCta: React.VFC<TwoColCtaFragment> = ({ left, right }) => (
  <ToolCta>
    {left?.[0] && (
      <ToolCta item>
        <ToolCta title>{left[0].text}</ToolCta>
        {left[0].subtext && (
          <ToolCta subText>{html(left[0].subtext)}</ToolCta>
        )}
        {left[0].button && (
          <ToolCta cta href={left[0].button.url || '#'}>
            {left[0].button.text}
          </ToolCta>
        )}
      </ToolCta>
    )}
    {right?.[0] && (
      <ToolCta item>
        <ToolCta title>{right[0].text}</ToolCta>
        {right[0].subtext && (
          <ToolCta subText>{right[0].subtext}</ToolCta>
        )}
        {right[0].button && (
          <ToolCta cta href={right[0].button.url || '#'}>
            {right[0].button.text}
          </ToolCta>
        )}
      </ToolCta>
    )}
  </ToolCta>
)

export const FourColCta: React.VFC<FourColCtaFragment> = ({
  ctaTitle: title,
  ctaSubtitle: subtitle,
  topLeft,
  topRight,
  bottomLeft,
  bottomRight,
}) => {
  const sections = nonNull([topLeft, topRight, bottomLeft, bottomRight])

  return (
    <ContactCTA>
      {(title || subtitle) && (
        <ContactCTA headingWrapper>
          {title && (
            <ContactCTA title>{title}</ContactCTA>
          )}
          {subtitle && (
            <ContactCTA subtitle>{subtitle}</ContactCTA>
          )}
        </ContactCTA>
      )}

      <ContactCTA contentWrapper>
        {sections.map((sections, index) => {
          const section = sections[0]

          if (!section) {
            return null
          }

          return (
            <ContactCTA key={index} section type="wrapper" href={section.button?.url || '#'}>
              {section.icon && (
                <ContactCTA icon iconCode={section.icon}/>
              )}
              <ContactCTA section type="content">
                {section.button && (
                  <ContactCTA title>{section.button.text}</ContactCTA>
                )}
                {section.subtext && (
                  <ContactCTA subtitle>{section.subtext}</ContactCTA>
                )}
              </ContactCTA>
            </ContactCTA>
          )
        })}
      </ContactCTA>
    </ContactCTA>
  )
}

export const FcpApplyNowCTA: React.VFC<FcpApplyNowFragment> = ({ cta_apply_now }) => {
  if(cta_apply_now?.[0] && cta_apply_now?.[0]?.__typename === 'applyNowCtaProducts_default_Entry') {
    if (cta_apply_now?.[0]?.applyNowModule?.[0]?.__typename === 'applyNowModule_BlockType') {
      const { applyNowBlock, applyNowMiddleColumn, applyNowRightColumn } = cta_apply_now?.[0]?.applyNowModule?.[0]
      
      return (
        <AccountsCard>
          {
            applyNowBlock && applyNowBlock?.[0]?.columnContent && applyNowBlock?.[0].__typename === 'applyNowBlock_leftColumn_BlockType' && (
              <AccountsCard title>
                {html(applyNowBlock?.[0].columnContent)}
              </AccountsCard>
            )
          }
          {
            applyNowMiddleColumn &&
        <AccountsCard section>
          {nonNull(applyNowMiddleColumn).map((contentData, index) => {
            if (contentData && contentData.__typename === 'applyNowMiddleColumn_middleColumn_BlockType') {
              if (contentData?.heading?.[0]?.headingLink?.url && contentData?.heading?.[0]?.heading && contentData?.heading?.[0]?.icon) {
                return (
                  <AccountsCard subsection key={index}>
                    <AccountsCard
                      iconHeading
                      icon={contentData?.heading?.[0]?.icon as string}
                    >
                      <Link legacyBehavior={true} aria-label={contentData?.heading?.[0]?.headingLink?.url as string} href ={contentData?.heading?.[0]?.headingLink?.url as string} passHref>
                        <StyledApplyNowLink
                          aria-label={contentData?.heading?.[0]?.headingLink?.url as string}
                          target = {contentData?.heading?.[0]?.headingLink?.target === '_blank' ? '_blank' : '_self'}
                        > 
                          {contentData?.heading?.[0]?.heading} 
                        </StyledApplyNowLink>
                      </Link>
                    </AccountsCard>
                    {
                      contentData.__typename === 'applyNowMiddleColumn_middleColumn_BlockType' && isNotNull(contentData.columnContent) &&
                <div>
                  {html(contentData?.columnContent)}
                </div>
                    }
                  </AccountsCard>
                )
              } else if (contentData?.heading?.[0]?.icon && contentData?.heading?.[0]?.heading) {
                return (
                  <AccountsCard subsection key={index}>
                    <AccountsCard
                      iconHeading
                      icon={contentData?.heading?.[0]?.icon as string}
                    >
                      {contentData?.heading?.[0]?.heading} 
                    </AccountsCard>
                    {
                      contentData.__typename === 'applyNowMiddleColumn_middleColumn_BlockType' && isNotNull(contentData.columnContent) &&
                <div>
                  {html(contentData?.columnContent)}
                </div>
                    }
                  </AccountsCard>
                )
              } else if ( isNotNull(contentData?.columnContent) ) {
                return (
                  <AccountsCard subsection key ={index}>
                    <div>
                      {html(contentData?.columnContent)}
                    </div>
                  </AccountsCard>
                )
              }
            } 
            
            return null
          })}
        </AccountsCard>
          }
          {
            applyNowRightColumn &&
        <AccountsCard section>
          {nonNull(applyNowRightColumn).map((contentData, index) => {
            if (contentData && contentData.__typename === 'applyNowRightColumn_rightColumn_BlockType') {
              if (contentData?.heading?.[0]?.headingLink?.url && contentData?.heading?.[0]?.heading && contentData?.heading?.[0]?.icon) {
                return (
                  <AccountsCard subsection key={index}>
                    <AccountsCard
                      iconHeading
                      icon={contentData?.heading?.[0]?.icon as string}
                    >
                      <Link aria-label = {contentData?.heading?.[0]?.headingLink?.url as string} href ={contentData?.heading?.[0]?.headingLink?.url as string} passHref>
                        <StyledApplyNowLink
                          aria-label = {contentData?.heading?.[0]?.headingLink?.url as string}
                          target = {contentData?.heading?.[0]?.headingLink?.target === '_blank' ? '_blank' : '_self'}
                        > 
                          {contentData?.heading?.[0]?.heading} 
                        </StyledApplyNowLink>
                      </Link>
                    </AccountsCard>
                    {
                      contentData.__typename === 'applyNowRightColumn_rightColumn_BlockType' && isNotNull(contentData.columnContent) &&
                <div>
                  {html(contentData?.columnContent)}
                </div>
                    }
                  </AccountsCard>
                )
              } else if (contentData?.heading?.[0]?.icon && contentData?.heading?.[0]?.heading) {
                return (
                  <AccountsCard subsection key={index}>
                    <AccountsCard
                      iconHeading
                      icon={contentData?.heading?.[0]?.icon as string}
                    >
                      {contentData?.heading?.[0]?.heading} 
                    </AccountsCard>
                    {
                      contentData.__typename === 'applyNowRightColumn_rightColumn_BlockType' && isNotNull(contentData.columnContent) &&
                <div>
                  {html(contentData?.columnContent)}
                </div>
                    }
                  </AccountsCard>
                )
              } else if ( isNotNull(contentData?.columnContent) ) {
                return (
                  <AccountsCard subsection key ={index}>
                    <div>
                      {html(contentData?.columnContent)}
                    </div>
                  </AccountsCard>
                )
              }
            } 
            
            return null
          })}
        </AccountsCard>
          }
        </AccountsCard>
      )
    }

    return null
  }

  return null
}