import { gql } from '@apollo/client'
import { Typography } from '@material-ui/core'
import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'
import { FAQAccordions, NEWS_FAQS_FRAGMENT } from '../../../templates/newsDetail/fragments'
import { NewsFaqFragment } from '../../../templates/newsDetail/fragments/__generated__/accordionFAQs'
import { FAQHeader, StyledAnchor } from '../../../templates/newsDetail/styledNewsDetail'
import { isNotNull, nonNull } from '../../../utils/nonNull'
import { Contained } from '../../contained/contained'
import { FlexibleContent } from '../flexibleContent'
import { FaqsFragment } from './__generated__/faqs'

export type FaqsProps = FaqsFragment

const StyledFAQTitle = styled(Typography)`
  font-weight: 500;
  font-size: ${props => props.theme.typography.pxToRem(15)};

  ${props => props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(17)};
    margin-left: 0;
  }
`

export const Faqs: React.FC<PropsWithChildren<FaqsProps>> = ({ category, faqs }) => {
  if (!faqs) {
    return null
  }

  return (
    <FlexibleContent row keepMargins>
      <Contained>
        {category?.[0] && (
          <FAQHeader
            direction="row"
            container
            justifyContent="space-between"
            alignItems={'center'}
          >
            <StyledFAQTitle variant="h5">Frequently asked questions</StyledFAQTitle>
            <StyledAnchor href={`/help-and-support/faqs?category=${category[0].id}`}>
              {isNotNull(category[0].title) ? `View all ${category[0].title} FAQs` : 'View all FAQs'}
            </StyledAnchor>
          </FAQHeader>
        )}
        <FAQAccordions data={nonNull(faqs) as NewsFaqFragment[]} />
      </Contained>
    </FlexibleContent>
  )
}

export const FAQS_FRAGMENT = gql`
  ${NEWS_FAQS_FRAGMENT}
  
  fragment FaqsFragment on flexibleContent_faqs_BlockType {
    category {
      id
      title
    }
    faqs {
      ...NewsFAQFragment
    }
  }
`
