import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons'
import Grid from '@material-ui/core/Grid'
import React, { ComponentProps, PropsWithChildren } from 'react'
import {
  StyledListItem,
  StyledPrices,
  StyledTableContent,
  StyledTableDisclaimer,
  StyledTableListWrapper,
  StyledTableRightContainer,
  StyledTableRightHeader,
  StyledTableRow,
  StyledTableSubTitle,
  StyledTableText,
  StyledTableTitle,
  StyledTableWrapper,
  StyledTickIcon,
  StyledTitleRow,
} from './styledFeesBenefitsTable'


export type TableWrapperProps = ComponentProps<typeof StyledTableWrapper> & {
    // 
  }

export type TableTitleWrapperProps = ComponentProps<typeof StyledTitleRow> & {
    //
  }
  
export type TableLeftTitleProps = ComponentProps<typeof StyledTableTitle> & {
    //
  }
  
export type TableRightTitleProps = ComponentProps<typeof StyledTableTitle> & {
    //
  }

export type TableSubTitleProps = ComponentProps<typeof StyledTableSubTitle> & {
    //
  }

export type TableContentProps = ComponentProps<typeof StyledTableContent> & {
    //
  }
  
export type TableLeftContentProps = ComponentProps<typeof StyledTableContent> & {
    //
  }

export type TableRightContentProps = ComponentProps<typeof StyledTableContent> & {
    //
  }

export type TableTextProps = ComponentProps<typeof StyledTableText> & {
    //
  }

export type TableListProps = ComponentProps<typeof StyledTableListWrapper> & {
    //
  }

export type TableListItemProps = ComponentProps<typeof StyledListItem> & {
    //
  }
  
export type TableDisclaimerProps = ComponentProps<typeof StyledTableDisclaimer> & {
    //
  }

export type TableProps =
  | (
    {
        title?: never
        titleLeft?: never
        titleRight?: never
        subTitle?: never
        row?: never
        contentLeft?: never
        contentRight?: never
        text?: never
        list?: never
        item?: never
        disclaimer?: never
    } & TableWrapperProps
    )
    | (
    {
        title: true
        titleLeft?: never
        titleRight?: never
        subTitle?: never
        row?: never
        contentLeft?: never
        contentRight?: never
        text?: never
        list?: never
        item?: never
        disclaimer?: never
    } & TableTitleWrapperProps
      )
    | (
    {
        title?: never
        titleLeft: true
        titleRight?: never
        subTitle?: never
        row?: never
        contentLeft?: never
        contentRight?: never
        text?: never
        list?: never
        item?: never
        disclaimer?: never
    } & TableLeftTitleProps
    )
    | (
      {
        title?: never
        titleLeft?: never
        titleRight: true
        subTitle?: never
        row?: never
        contentLeft?: never
        contentRight?: never
        text?: never
        list?: never
        item?: never
        disclaimer?: never
    } & TableRightTitleProps
    )
    | (
    {
        title?: never
        titleLeft?: never
        titleRight?: never
        subTitle: true
        row?: never
        contentLeft?: never
        contentRight?: never
        text?: never
        list?: never
        item?: never
        disclaimer?: never
    } & TableSubTitleProps
    )
    | (
    {
        title?: never
        titleLeft?: never
        titleRight?: never
        subTitle?: never
        row: true
        contentLeft?: never
        contentRight?: never
        text?: never
        list?: never
        item?: never
        disclaimer?: never
    } & TableContentProps
    )
    | (
    {
        title?: never
        titleLeft?: never
        titleRight?: never
        subTitle?: never
        row?: never
        contentLeft: true
        contentRight?: never
        text?: never
        list?: never
        item?: never
        disclaimer?: never
    } & TableLeftContentProps
    )
    | (
    {
        title?: never
        titleLeft?: never
        titleRight?: never
        subTitle?: never
        row?: never
        contentLeft?: never
        contentRight: true
        text?: never
        list?: never
        item?: never
        disclaimer?: never
    } & TableRightContentProps
    )
    | (
    {
        title?: never
        titleLeft?: never
        titleRight?: never
        subTitle?: never
        row?: never
        contentLeft?: never
        contentRight?: never
        text: true
        list?: never
        item?: never
        disclaimer?: never
    } & TableTextProps
    )
    | (
    {
        title?: never
        titleLeft?: never
        titleRight?: never
        subTitle?: never
        row?: never
        contentLeft?: never
        contentRight?: never
        text?: never
        list: true
        item?: never
        disclaimer?: never
    } & TableListProps
    )
    | (
    {
        title?: never
        titleLeft?: never
        titleRight?: never
        subTitle?: never
        row?: never
        contentLeft?: never
        contentRight?: never
        text?: never
        list?: never
        item: true
        disclaimer?: never
    } & TableListItemProps
    )
    | (
    {
        title?: never
        titleLeft?: never
        titleRight?: never
        subTitle?: never
        row?: never
        contentLeft?: never
        contentRight?: never
        text?: never
        list?: never
        item?: never
        disclaimer: true
    } & TableDisclaimerProps
    )


export const Table: React.FC<PropsWithChildren<TableProps>> =({ title, titleLeft, titleRight, subTitle, row, contentLeft, contentRight, text, list, item, disclaimer, children, ...props }) => {
  if (title) {
    return (
      <StyledTitleRow
        container
        direction="row"
        alignItems="baseline"
        justifyContent="space-between"
      >
        {children}
      </StyledTitleRow>
    )
  }

  if (titleLeft) {
    return (
      <Grid
        item
        xs
        md
        lg
        {...props}
      >
        <StyledTableTitle>{children}</StyledTableTitle>
      </Grid>
    )
  }

  if (titleRight) {
    return (
      <StyledTableRightHeader
        item
        xs={4}
        md={2}
        {...props}
      >
        <StyledTableTitle>{children}</StyledTableTitle>
      </StyledTableRightHeader>
    )
  }

  if (subTitle) {
    return (
      <Grid
        item
        xs={12}
      >
        <StyledTableSubTitle>
          {children}
        </StyledTableSubTitle>
      </Grid>
    )
  }

  if (row) {
    return (
      <StyledTableRow
        container
        direction="row"
        alignItems="stretch"
        justifyContent="space-between"
        wrap="nowrap"
      >
        {children}

      </StyledTableRow>
    )
  }

  if (contentLeft) {
    return (
      <Grid
        item
        xs={12}
        {...props}
      >
        <StyledTableContent
          container
          direction="column"
          justifyContent="center"
        >
          {children}
        </StyledTableContent>
      </Grid>
    )
  }

  if (contentRight) {
    return (
      <RightColumnContent {...props}>{children}</RightColumnContent>
    )
  }

  if (text) {
    return (
      <StyledTableText
        item
        xs={12}
      >
        {children}
      </StyledTableText>
    )
  }

  if (list) {
    return (
      <StyledTableListWrapper>
        <ul>
          {children}
        </ul>
      </StyledTableListWrapper>
    )
  }

  if (item) {
    return (
      <StyledListItem>{children}</StyledListItem>
    )
  }

  if (disclaimer) {
    return (
      <StyledTableDisclaimer>
        {children}
      </StyledTableDisclaimer>
    )
  }

  return(

    <StyledTableWrapper 
      direction="column"
      alignItems="stretch"
      justifyContent="flex-start"
      container
      {...props}
    >
      {children}
    </StyledTableWrapper>
  )
}

const RightColumnContent: React.FC<PropsWithChildren<TableRightContentProps>> = ({ children, ...props }) => {
  if (children) {
    return (
      <StyledTableRightContainer
        item
        xs={3}
        md={2}
        {...props}
      >
        <StyledTableContent>
          <StyledPrices>
            {children}
          </StyledPrices>
        </StyledTableContent>
      </StyledTableRightContainer>
    )
  }

  return (
    <StyledTableRightContainer
      item
      xs={3}
      md={2}
      {...props}
    >
      <StyledTableContent
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <StyledTickIcon icon={faCheckCircle} />
      </StyledTableContent>
    </StyledTableRightContainer>
  )
}