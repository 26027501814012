
import { Container, Typography } from '@material-ui/core'
import { Disclaimer as DisclaimerBase } from '../../components/disclaimer/disclaimer'
import styled from 'styled-components'
import { rem } from '../../utils/rem'


export const StyledPageDisclaimerContainer = styled(Container)`
padding: ${props => rem(70, props.theme)} 0;

${props => props.theme.breakpoints.up('sm')} {
  padding: ${props => rem(103, props.theme)} 0;
}
`
export const StyledPageDisclaimerSymbol = styled(Typography)`
  vertical-align: middle;
  font-size: ${props => props.theme.typography.pxToRem(10)};
  font-weight: 500;
  padding-right: 3px;
`

export const StyledPageDisclaimerBase = styled(DisclaimerBase)`
  & > p {
    display: inline;
  }
`
export const StyledPageDisclaimerWrapper = styled(DisclaimerBase)`
  background: #f2f2f2;
  gap: 0;

  > div:first-child {
    background: #fff;
    padding-bottom: ${props => props.theme.typography.pxToRem(35)};
  }

  > div:nth-child(2) {
    padding-top: ${props => props.theme.typography.pxToRem(25)};
    padding-bottom: 0;
  }

  > div:nth-child(n+3) {
    padding-bottom: 0;
  }

  > div:last-child {
    padding-bottom: ${props => props.theme.typography.pxToRem(25)};
  }
`
export const StyledPageDisclaimerTitle= styled(DisclaimerBase)`
  & > h5 {
    margin-left: ${props => props.theme.typography.pxToRem(20)};
  }
`