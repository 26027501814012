import React from 'react'
import Script from 'next/script'

export const WIDGET_WORKS_CODES = {
  borrowingPower: 'makoga',
  budgetPlanner: 'puchika',
  buyingSellingCosts: 'dahotsusu',
  depositPlanner: 'bokajiro',
  expensePlanner: 'benogayu',
  incomeTax: 'bugoreki',
  keyFactsSheet: 'mimede',
  loanComparison: 'betzudoko',
  personalLoan: 'betzusapi',
  repayment: 'damorona',
  repaymentCampaign: 'dabeyuyu',
  stampDuty: 'dadizena',
} as const

export type WidgetWorksFrame = keyof typeof WIDGET_WORKS_CODES

export const isWidgetWorksFrame = (key: string): key is WidgetWorksFrame => key in WIDGET_WORKS_CODES

export const getFrameCode = (frame: WidgetWorksFrame) => WIDGET_WORKS_CODES[frame]

export const getFrameScript = (frame: WidgetWorksFrame) => `https://calcs.widgetworks.com.au/s/${getFrameCode(frame)}/live.js`

export const WidgetWorksFrame: React.VFC<{ frame: WidgetWorksFrame }> = ({ frame }) => (
  <>
    <Script strategy="afterInteractive" src={getFrameScript(frame)} />
    <iframe title="Widget Works" id={`wiwo-${frame}`} src="about:blank" frameBorder="0" width="100%" height="200" data-wiwo-init="false" />
    <Script async strategy="lazyOnload" src="https://calcs.widgetworks.com.au/widget/widget-scout.min.js" />
  </>
)