import React, { PropsWithChildren } from 'react';
import {
  FeaturesListContainer,
  FeaturesListContainerProps,
  FeaturesListDescription,
  FeaturesListDescriptionProps,
  FeaturesListIcon,
  FeaturesListIconProps,
  FeaturesListTitle,
  FeaturesListTitleProps,
} from './FeaturesListItems';

export type FeaturesListProps = ({ icon?: boolean; title?: boolean; description?: boolean }) & (
  // icon
  | ({ icon: true } & FeaturesListIconProps )
  // title
  | ({ title: true } & FeaturesListTitleProps )
  // description
  | ({ description: true } & FeaturesListDescriptionProps )
  | FeaturesListContainerProps
)

export const FeaturesList: React.FC<PropsWithChildren<FeaturesListProps>> = ({ icon, title, description, children, ...props }) => {
  if (icon) {
    return (
      <FeaturesListIcon {...props as FeaturesListIconProps}>
        {children}
      </FeaturesListIcon>
    )
  }

  if (title) {
    return (
      <FeaturesListTitle {...props}>
        {children}
      </FeaturesListTitle>
    )
  }

  if (description) {
    return (
      <FeaturesListDescription {...props}>
        {children}
      </FeaturesListDescription>
    )
  }

  return (
    <FeaturesListContainer {...props}>
      {children}
    </FeaturesListContainer>
  )
}