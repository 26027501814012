import { gql } from '@apollo/client'
import React, { PropsWithChildren, useContext } from 'react'
import { Breadcrumb, Breadcrumbs } from '../../../components/breadcrumbs/Breadcrumbs'
import { IMAGE_FRAGMENT } from '../../../fragments/image'
import { SecondaryPageBanner } from '../../../fragments/secondaryPageBanner'
import { GlobalContext } from '../../../globals/context'
import { isNotNull } from '../../../utils/nonNull'
import { NewsDetailBannerFragment } from './__generated__/secondaryBanner'

export type SecondaryBannerProps = {
  title: string
  uri: string
  data: NewsDetailBannerFragment[]
}

export const NewsBanner: React.FC<PropsWithChildren<SecondaryBannerProps>> = ({ title, uri, data }) => {
  const { fallback } = useContext(GlobalContext)

  if (!data[0] || data[0].__typename !== 'newsDetailBanner_BlockType' || fallback?.globalSet?.__typename !== 'siteSettings_GlobalSet') {
    return null
  }

  const { newsFallbackImage } = fallback.globalSet

  const { bannerText, bannerSubText, primaryBannerButton, secondaryBannerButton, bannerImage } = data[0]
  
  const image = isNotNull(bannerImage) && bannerImage.length> 0 ? bannerImage: newsFallbackImage as typeof bannerImage

  return (
    <SecondaryPageBanner
      title={bannerText || title}
      subTitle={bannerSubText}
      primaryButton={primaryBannerButton}
      secondaryButton={secondaryBannerButton}
      image={image}
      after={'grey'}
      breadcrumbs={(
        <Breadcrumbs>
          <Breadcrumb href={'/'} home text={'Home'} />
          <Breadcrumb href={'/community/news'} text={'News'} />
          <Breadcrumb href={`/${uri}`} text={title} />
        </Breadcrumbs>
      )}
    />
  )
}

export const NEWS_DETAIL_BANNER_FRAGMENT = gql`
  ${IMAGE_FRAGMENT}

  fragment NewsDetailBannerFragment on newsDetailBanner_BlockType {
    bannerText
    bannerSubText
    primaryBannerButton {
      target
      customText
      url
    }
    secondaryBannerButton {
      customText
      target
      url
    }
    bannerImage {
      ...ImageFragment
    }
  }
`
