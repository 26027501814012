import { gql } from '@apollo/client'
import React, { PropsWithChildren } from 'react'
import { LinkGrid as BaseLinkGrid } from '../../linkgrid/LinkGrid'
import { FlexibleContent } from '../flexibleContent'
import { LinkGridFragment } from './__generated__/linkGrid'

export type LinkGridProps = LinkGridFragment

export const LinkGrid: React.FC<PropsWithChildren<LinkGridProps>> = ({ links }) => {
  return (
    <FlexibleContent row>
      {links && (
        <BaseLinkGrid variant="dark">
          {links.map((link, i) => link?.subLink && (
            <BaseLinkGrid key={i} linkWrapper>
              <BaseLinkGrid link href={link.subLink.url || '#'} {...link.subLink?.target === '_blank' && { target: '_blank' }}>
                {link.subLink.text}
              </BaseLinkGrid>
            </BaseLinkGrid>
          ))}
        </BaseLinkGrid>
      )}
    </FlexibleContent>
  )
}

export const LINK_GRID_FRAGMENT = gql`
  fragment LinkGridFragment on flexibleContent_linkGrid_BlockType {
    links {
      ... on links_BlockType {
        subLink {
          target
          text
          url
        }
      }
    }
  }
`
