import { gql } from '@apollo/client'
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints'
import React, { PropsWithChildren } from 'react'
import styled, { css } from 'styled-components'
import { rem } from '../../../utils'
import { FlexibleContentRow } from '../flexibleContentRow'
import { SpacerFragment } from './__generated__/spacer'

export type SpacerProps = SpacerFragment

type SpacerSizes = 'small' | 'large'

const margins: Record<SpacerSizes, Partial<Record<Breakpoint, number>>> = {
  small: {
    xs: 75,
  },
  large: {
    xs: 75,
    sm: 160,
  },
}

export const SpacerRow = styled(FlexibleContentRow)<{ $size: SpacerSizes }>`
  && {
    ${({ $size }) => Object.keys(margins[$size]).map((key: Breakpoint) => css`
      ${props => props.theme.breakpoints.up(key)} {
        margin-block-start: ${props =>rem((margins[$size][key] || 0) / 2, props.theme)};
        margin-block-end: ${props =>rem((margins[$size][key] || 0) / 2, props.theme)};
      }
    `)}
  }
`

export const Spacer: React.FC<PropsWithChildren<SpacerProps>> = ({ size: large, showHr }) => {
  return (
    <SpacerRow $size={large ? 'large' : 'small'}>
      {showHr && <hr />}
    </SpacerRow>
  )
}

export const SPACER_FRAGMENT = gql`
  fragment SpacerFragment on flexibleContent_spacer_BlockType {
    size
    showHr
  }
`
