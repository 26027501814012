import { gql } from '@apollo/client'
import React, { PropsWithChildren, useContext } from 'react'
import styled from 'styled-components'
import { DisclaimerContext } from '../../../context/disclaimerContext'
import { html } from '../../../utils/html'
import { nonNull } from '../../../utils/nonNull'
import { Disclaimer as BaseDisclaimer } from '../../disclaimer/disclaimer'
import { FlexibleContent } from '../flexibleContent'
import {
  StyledFcpDisclaimerBase,
  StyledFcpDisclaimerBlock,
  StyledFcpDisclaimerSymbol,
} from '../flexibleTableStyles'
import { DisclaimerFcpFragment } from './__generated__/disclaimer'

const DisclaimerTitle = styled(BaseDisclaimer)`
  & > h5 {
    margin-left: ${props => props.theme.typography.pxToRem(20)};
  }
`

export type DisclaimerProps = DisclaimerFcpFragment

export const Disclaimer: React.FC<PropsWithChildren<DisclaimerProps>> = ({ disclaimers, generalDisclaimers: general }) => {
  const { disclaimerArray, iconMap, pageId } = useContext(DisclaimerContext)

  return (
    <FlexibleContent $keepMargins={false} row>
      {((disclaimerArray && disclaimerArray.length > 0) || (disclaimers && disclaimers.length > 0) || (general && general.length > 0)) && (
        <StyledFcpDisclaimerBlock>
          <DisclaimerTitle id={`disclaimer_block_${pageId}`} title>{'INFORMATION YOU SHOULD KNOW'}</DisclaimerTitle>
          {general && nonNull(general).map((disclaimer, index) => {
            if (disclaimer.__typename === 'homeLoanDisclaimers_default_Entry' || disclaimer.__typename === 'personalloandisclaimers_default_Entry' || disclaimer.__typename === 'customDisclaimers_default_Entry') {
              return (
                <StyledFcpDisclaimerBase key={index} textBlock>
                  {disclaimer.disclaimerText && html(disclaimer.disclaimerText)}
                </StyledFcpDisclaimerBase>
              )
            }

            return null
          })}
          {disclaimerArray && nonNull(disclaimerArray).map((d, index) => {
            return (
              <StyledFcpDisclaimerBase key={index} textBlock>
                <StyledFcpDisclaimerSymbol>
                  {iconMap.get(d.disclaimerId)}
                </StyledFcpDisclaimerSymbol>
                {d?.disclaimerText && html(d.disclaimerText)}
              </StyledFcpDisclaimerBase>
            )
          })}
          {disclaimers && nonNull(disclaimers).map((disclaimer, index) => {
            if (disclaimer.__typename === 'homeLoanDisclaimers_default_Entry' || disclaimer.__typename === 'personalloandisclaimers_default_Entry' || disclaimer.__typename === 'customDisclaimers_default_Entry') {
              return (
                <StyledFcpDisclaimerBase key={index} textBlock>
                  <StyledFcpDisclaimerSymbol key={index}>
                    {index+1}
                  </StyledFcpDisclaimerSymbol>
                  {disclaimer.disclaimerText && html(disclaimer.disclaimerText)}
                </StyledFcpDisclaimerBase>  
              )
            }

            return null
          })}
        </StyledFcpDisclaimerBlock>
      )}
    </FlexibleContent>
  )
}

export const DISCLAIMER_FCP_FRAGMENT = gql`
  fragment DisclaimerFCPFragment on flexibleContent_disclaimer_BlockType {
    generalDisclaimers {
      ... on customDisclaimers_default_Entry {
        id
        title
        disclaimerText
      }
      ... on homeLoanDisclaimers_default_Entry {
        id
        title
        disclaimerText
      }
      ... on personalloandisclaimers_default_Entry {
        id
        title
        disclaimerText
      }
    }
    disclaimers {
      ... on customDisclaimers_default_Entry {
        id
        title
        disclaimerText
      }
      ... on homeLoanDisclaimers_default_Entry {
        id
        title
        disclaimerText
      }
      ... on personalloandisclaimers_default_Entry {
        id
        title
        disclaimerText
      }
    }
  }
`
