import styled from 'styled-components'
import { Typography } from '@material-ui/core'
import { alpha } from '@material-ui/core/styles'
import { irem, rem } from '../../utils'

export const StyledFeaturesListContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${props => props.theme.breakpoints.up('sm')} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: ${props => props.theme.typography.pxToRem(40)};
    margin: 0;
  }

  ${props => props.theme.breakpoints.up('md')} {
    margin-top: ${irem(-36)};
  }
`

export const StyledFeaturesListItemWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  column-gap: ${props => props.theme.typography.pxToRem(29)};
  padding-top: ${props => props.theme.typography.pxToRem(36)};
  padding-bottom: ${props => props.theme.typography.pxToRem(20)};
  border-bottom: solid ${props => props.theme.typography.pxToRem(1)} ${alpha('#707070', 0.66)};

  ${props => props.theme.breakpoints.up('sm')} {
    flex-direction: column;
    height: 100%;
    padding-bottom: ${props => props.theme.typography.pxToRem(30)};
    border-bottom: solid ${props => props.theme.typography.pxToRem(5)} #f2f2f2;
  }

  &:last-of-type {
    border-bottom: none;

    ${props => props.theme.breakpoints.up('sm')} {
      border-bottom: solid ${props => props.theme.typography.pxToRem(5)} #f2f2f2;
    }
  }
`

export const StyledFeaturesListSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.typography.pxToRem(13)};
`

export const StyledCreditUnionIconContainer = styled.div`
  display: inherit;
  margin-top: ${props => props.theme.typography.pxToRem(-3)};
  min-width: ${props => rem(30, props.theme)};
  max-width: ${props => rem(30, props.theme)};

  ${props => props.theme.breakpoints.up('sm')} {
    margin-top: 0;
    height: ${props => props.theme.typography.pxToRem(45)};
  }

  ${props => props.theme.breakpoints.up('md')} {
    min-width: ${props => rem(40, props.theme)};
    max-width: ${props => rem(40, props.theme)};
  }

  img {
    max-width: 100%;
  }
`

export const StyledTitleContainer = styled.div`
  display: flex;
  gap: ${props => props.theme.typography.pxToRem(5)};

  ${props => props.theme.breakpoints.up('sm')} {
    padding-top: ${props => props.theme.typography.pxToRem(34)};
  }
`

export const StyledTitle = styled(Typography)`
  font-size: ${props => props.theme.typography.pxToRem(20)};
  font-weight: 500;
  color: ${props => props.theme.palette.text.primary};
`

export const StyledAsterisk = styled(Typography)`
  font-size: ${props => props.theme.typography.pxToRem(20)};
  color: ${props => props.theme.palette.primary.main};
  font-weight: 500;

  ${props => props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(15)};
  }
`

export const StyledDescriptionContainer = styled.div`
  ${props => props.theme.breakpoints.up('sm')} {
    padding-top: ${props => props.theme.typography.pxToRem(10)};
  }
`

export const StyledDescription = styled(Typography)`
  font-size: ${props => props.theme.typography.pxToRem(14)};
  line-height: ${props => props.theme.typography.pxToRem(24)};

  ${props => props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(15)};
    line-height: ${props => props.theme.typography.pxToRem(28)};
  }

  > a {
    font-size: ${props => props.theme.typography.pxToRem(14)};

    ${props => props.theme.breakpoints.up('sm')} {
      font-size: ${props => props.theme.typography.pxToRem(15)};
    }
  }
`