import { gql } from '@apollo/client'
import Grid from '@material-ui/core/Grid'
import React, { PropsWithChildren } from 'react'
import { FLEXIBLE_CONTENT_BASE_SPACING } from '../../../constants/flexibleContentBaseSpacing'
import { html } from '../../../utils/html'
import { FlexibleContent } from '../flexibleContent'
import { ThreeColumnRowFragment } from './__generated__/threeColumnRow'

export type ThreeColumnRowProps = ThreeColumnRowFragment & {
  verticallyCentre: boolean
}

export const ThreeColumnRow: React.FC<PropsWithChildren<ThreeColumnRowProps>> = ({ column1, column2, column3, verticallyCentre }) => {
  const columns = [column1, column2, column3]

  return (
    <FlexibleContent row>
      <Grid container spacing={FLEXIBLE_CONTENT_BASE_SPACING} alignItems={verticallyCentre ? 'center' : 'flex-start'}>
        {columns.map((col, i) => (
          <Grid item key={i} xs={12} md={4}>
            {col && html(col)}
          </Grid>
        ))}
      </Grid>
    </FlexibleContent>
  )
}

export const THREE_COLUMN_ROW_FRAGMENT = gql`
  fragment ThreeColumnRowFragment on flexibleContent_threeColumnRow_BlockType {
    column1
    column2
    column3
    verticallyCentre
  }
`