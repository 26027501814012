import { gql } from '@apollo/client'
import React, { PropsWithChildren, useContext } from 'react'
import { CardDesignCarousel, CardDesignCarouselProps } from '../components/CardDesignCarousel/CardDesignCarousel'
import { CardDesignItem } from '../components/CardDesignCarousel/CardDesignItem'
import { GlobalContext } from '../globals/context'
import { Maybe } from '../gql'
import { truncate } from '../utils/truncate'
import { ImageFragment } from './__generated__/image'
import { RelatedContentCarouselFragment } from './__generated__/relatedContentCarousel'
import { IMAGE_FRAGMENT } from './image'

export type RelatedContentCarouselProps = CardDesignCarouselProps & {
  displayMode?: 'showExcerpts' | 'showDates'
  items?: RelatedContentCarouselFragment[]
}

export const RelatedContentCarousel: React.FC<PropsWithChildren<RelatedContentCarouselProps>> = ({ items, displayMode = 'showExcerpts', ...props }) => {
  const data = useRelatedContentItemData(items || [])

  return data.length > 0 ? (
    <CardDesignCarousel { ...props }>
      {data.map((data) => (
        <CardDesignItem key={data.uid} href={`/${data.uri}`}>
          {data.image && (
            <CardDesignItem image src={data.image.url} alt={data.image.__typename === 'images_Asset' ? data.image.alt ?? '' : ''} layout="fill"/>
          )}
          {displayMode === 'showDates' && (
            <CardDesignItem date>{data.date.toLocaleString('en-AU', { year: 'numeric', month: 'short', day: 'numeric' })}</CardDesignItem>
          )}
          <CardDesignItem title>{data.title ? truncate(data.title, 75) : 'Untitled'}</CardDesignItem>
          {displayMode === 'showExcerpts' && data.excerpt && (
            <CardDesignItem description>{truncate(data.excerpt, 160)}</CardDesignItem>
          )}
        </CardDesignItem>
      ))}
    </CardDesignCarousel>
  ) : null
}

export type ItemData = {
  uid: string
  title: string
  date: Date
  uri: string
  image?: Maybe<ImageFragment>
  excerpt?: Maybe<string>
}

const validEntryTypes = new Set([
  'glossaryPage_glossaryPage_Entry',
  'products_pages_product_listing_Entry',
  'products_pages_product_detail_Entry',
  'products_pages_product_fcp_Entry',
  'products_pages_product_fcp_Entry',
  'search_search_Entry',
  'faqLandingPage_faqLandingPage_Entry',
  'newsLandingPage_newsLandingPage_Entry',
  'news_newsEntryType_Entry',
])

export const useRelatedContentItemData = (items: Maybe<RelatedContentCarouselFragment[]>): ItemData[] => {
  const { fallback } = useContext(GlobalContext)
  let flexibleContentImageFallback, newsFallbackImage, productDetailImageFallback, productListingImageFallback

  if (fallback.globalSet?.__typename === 'siteSettings_GlobalSet') {
    flexibleContentImageFallback = fallback.globalSet.flexibleContentImageFallback
    newsFallbackImage = fallback.globalSet.newsFallbackImage
    productDetailImageFallback = fallback.globalSet.productDetailImageFallback
    productListingImageFallback = fallback.globalSet.productListingImageFallback
  }

  return items ? items.filter((item) => validEntryTypes.has(item.__typename as string)).map(item => {
    const data = {
      uid: item.uid as string,
      title: item.title as string,
      uri: item.uri as string,
      date: new Date(item.postDate),
    }

    switch (item.__typename) {
    case 'news_newsEntryType_Entry':
      return {
        ...data,
        image: item?.relatedContentMetaAlt?.[0]?.metaImageAlt?.[0] ?? (item.newsDetailBanner?.[0]?.bannerImage?.[0] ?? newsFallbackImage?.[0]),
        title: item?.relatedContentMetaAlt?.[0]?.metaTitleAlt ?? data.title,
        excerpt: item?.relatedContentMetaAlt?.[0]?.metaExcerptAlt ?? (item.newsArticleDescription ?? item?.newsDetailBanner?.[0]?.bannerSubText),
      }
    case 'newsLandingPage_newsLandingPage_Entry':
      return {
        ...data,
        image: item?.relatedContentMeta?.[0]?.metaImage?.[0],
        title: item?.relatedContentMeta?.[0]?.metaTitle ?? data.title,
        excerpt: item?.relatedContentMeta?.[0]?.metaexcerpt,
      }
    case 'products_pages_product_detail_Entry':
      return {
        ...data,
        image: item?.relatedContentMetaAlt?.[0]?.metaImageAlt?.[0] ?? (item?.secondaryPageBanner?.[0]?.secondaryBannerImage?.[0] ?? productDetailImageFallback?.[0]),
        title: item?.relatedContentMetaAlt?.[0]?.metaTitleAlt ?? data.title,
        excerpt: item?.relatedContentMetaAlt?.[0]?.metaExcerptAlt ?? item?.metaExcerpt,
      }
    case 'products_pages_product_fcp_Entry':
      return {
        ...data,
        image: item?.relatedContentMetaAlt?.[0]?.metaImageAlt?.[0] ?? (item?.secondaryPageBanner?.[0]?.secondaryBannerImage?.[0] ?? flexibleContentImageFallback?.[0]),
        title: item?.relatedContentMetaAlt?.[0]?.metaTitleAlt ?? data.title,
        excerpt: item?.relatedContentMetaAlt?.[0]?.metaExcerptAlt ?? item?.metaExcerpt,
      }
    case 'products_pages_product_listing_Entry':
      return {
        ...data,
        image: item?.relatedContentMetaAlt?.[0]?.metaImageAlt?.[0] ?? (item?.secondaryPageBanner?.[0]?.secondaryBannerImage?.[0] ?? productListingImageFallback?.[0]),
        title: item?.relatedContentMetaAlt?.[0]?.metaTitleAlt ?? data.title,
        excerpt: item?.relatedContentMetaAlt?.[0]?.metaExcerptAlt ?? item?.metaExcerpt,
      }
    case 'faqLandingPage_faqLandingPage_Entry':
      return {
        ...data,
        image: item?.relatedContentMeta?.[0]?.metaImage?.[0],
        title: item?.relatedContentMeta?.[0]?.metaTitle ?? data.title,
        excerpt: item?.relatedContentMeta?.[0]?.metaexcerpt,
      }
    case 'glossaryPage_glossaryPage_Entry':
      return {
        ...data,
        image: item?.relatedContentMeta?.[0]?.metaImage?.[0],
        title: item?.relatedContentMeta?.[0]?.metaTitle ?? data.title,
        excerpt: item?.relatedContentMeta?.[0]?.metaexcerpt,
      }
    case 'search_search_Entry':
      return {
        ...data,
        image: item?.relatedContentMeta?.[0]?.metaImage?.[0],
        title: item?.relatedContentMeta?.[0]?.metaTitle ?? data.title,
        excerpt: item?.relatedContentMeta?.[0]?.metaexcerpt,
      }
    default:
      throw new Error(`Invalid type ${item.__typename}`)
    }
  }) : []
}

export const RELATED_CONTENT_CAROUSEL_FRAGMENT = gql`
  ${IMAGE_FRAGMENT}
  
  fragment RelatedContentCarouselFragment on EntryInterface {
    uid
    uri
    title
    postDate
    ... on glossaryPage_glossaryPage_Entry {
      relatedContentMeta {
        ... on relatedContentMeta_BlockType {
          id
          metaTitle
          metaexcerpt
          metaImage {
            ...ImageFragment
          }
        }
      }
    }
    ... on products_pages_product_listing_Entry {
      secondaryPageBanner {
        ... on secondaryPageBanner_BlockType {
          secondaryBannerImage {
            ...ImageFragment
          }
        }
      }
      relatedContentMetaAlt {
        ... on relatedContentMetaAlt_BlockType {
          metaTitleAlt
          metaExcerptAlt
          metaImageAlt {
            ...ImageFragment
          }
        }
      }
      metaExcerpt
    }
    ... on products_pages_product_detail_Entry {
      secondaryPageBanner {
        ... on secondaryPageBanner_BlockType {
          secondaryBannerImage {
            ...ImageFragment
          }
        }
      }
      relatedContentMetaAlt {
        ... on relatedContentMetaAlt_BlockType {
          metaTitleAlt
          metaExcerptAlt
          metaImageAlt {
            ...ImageFragment
          }
        }
      }
      metaExcerpt
    }
    ... on products_pages_product_fcp_Entry {
      secondaryPageBanner {
        ... on secondaryPageBanner_BlockType {
          secondaryBannerImage {
            ...ImageFragment
          }
        }
      }
      relatedContentMetaAlt {
        ... on relatedContentMetaAlt_BlockType {
          metaTitleAlt
          metaExcerptAlt
          metaImageAlt {
            ...ImageFragment
          }
        }
      }
      metaExcerpt
    }
    ...on search_search_Entry {
      relatedContentMeta {
        ...on relatedContentMeta_BlockType {
          id
          metaTitle
          metaexcerpt
          metaImage {
            ...ImageFragment
          }
        }
      }
    }
    ...on faqLandingPage_faqLandingPage_Entry {
      relatedContentMeta {
        ...on relatedContentMeta_BlockType {
          id
          metaTitle
          metaexcerpt
          metaImage {
            ...ImageFragment
          }
        }
      }
    }
    ...on newsLandingPage_newsLandingPage_Entry {
      relatedContentMeta {
        ...on relatedContentMeta_BlockType {
          id
          metaTitle
          metaexcerpt
          metaImage {
            ...ImageFragment
          }
        }
      }
    }
    ... on news_newsEntryType_Entry {
      newsArticleDescription
      newsDetailBanner {
        ... on newsDetailBanner_BlockType {
          bannerSubText
          bannerImage {
            ...ImageFragment
          }
        }
      }
      relatedContentMetaAlt {
        ... on relatedContentMetaAlt_BlockType {
          metaTitleAlt
          metaExcerptAlt
          metaImageAlt {
            ...ImageFragment
          }
        }
      }
    }
  }
`
