import { Container, Grid, Typography } from '@material-ui/core'
import styled from 'styled-components'
import { rem } from '../../utils'
import { StyledFcpAngleLine, StyledFlexibleContentWrapper } from '../../components/flexibleContent/flexibleContentWrapper'
import { StyledToolCtaContainer } from '../../components/toolcta/StyledToolCta'

export const StyledGreyBackground = styled.div`
  z-index: 1;
  position: relative;
  padding-top: ${props => rem(50, props.theme)};

  ${props => props.theme.breakpoints.up('sm')} {
    padding-top: ${props => rem(85, props.theme)};
  }

  &::before {
    content: "";
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: #f2f2f2;
    position: absolute;
    z-index: -1;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - ${props => rem(85, props.theme)}));

    ${props => props.theme.breakpoints.up('sm')} {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - ${props => rem(136, props.theme)}));
    }

    ${props => props.theme.breakpoints.up('md')} {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - ${props => rem(221, props.theme)}));
    }
  }

  ${props => props.theme.breakpoints.down('sm')} {
    ${StyledToolCtaContainer} {
      margin: 0;
      max-width: 100%;
    }
  }
`

export const StyledWhiteBackground= styled(Container)`
  margin-top: ${props => props.theme.typography.pxToRem(92)};
  margin-bottom: ${props => props.theme.typography.pxToRem(-70)};

  ${props => props.theme.breakpoints.up('sm')} {
    margin-bottom: ${props => props.theme.typography.pxToRem(-60)};
  }

  ${props => props.theme.breakpoints.up('md')} {
    margin-top: ${props => props.theme.typography.pxToRem(167)};
    margin-bottom: ${props => props.theme.typography.pxToRem(-160)};
  }
`

export const StyledNewsWrapper = styled.div`
  margin-bottom: ${props => props.theme.typography.pxToRem(102)};

  ${props => props.theme.breakpoints.up('md')} {
    margin-bottom: ${props => props.theme.typography.pxToRem(205)};
  }

  ${StyledFcpAngleLine} {
    display: none;
  }
  ${StyledFlexibleContentWrapper} {
    margin-top: 0;
  }
`

export const FAQHeader = styled(Grid)`
  padding-right: ${props => props.theme.typography.pxToRem(20)};

  && {
    margin-bottom: ${props => props.theme.typography.pxToRem(40)};

    ${props => props.theme.breakpoints.up('sm')} {
      margin-bottom: ${props => props.theme.typography.pxToRem(60)};
    }

    ${props => props.theme.breakpoints.up('md')} {
      margin-bottom: ${props => props.theme.typography.pxToRem(80)};
    }
  }
`

export const ComponentHeader = styled(Grid)`
  margin-bottom: ${props => props.theme.typography.pxToRem(80)};
`

export const ComponentWrapper = styled.div`
  margin-top: ${props => props.theme.typography.pxToRem(60)};
`

export const StyledHeading = styled(Typography)`
  font-weight: 500;
`

export const StyledAnchor = styled.a`
  color: black;
  font-weight: 500;
  font-size: ${props => props.theme.typography.pxToRem(15)};
`