import { gql } from '@apollo/client'
import Grid from '@material-ui/core/Grid'
import React, { PropsWithChildren } from 'react'
import { FLEXIBLE_CONTENT_BASE_SPACING } from '../../../constants/flexibleContentBaseSpacing'
import { html } from '../../../utils/html'
import { rem } from '../../../utils'
import styled from 'styled-components'
import { Button, ButtonProps } from '../../button/button'
import { FlexibleContent } from '../flexibleContent'
import { QuickExitCtaFragment } from './__generated__/quickExitCTA'

export type QuickExitCTAProps = QuickExitCtaFragment

export const StyledWrapper = styled.div`
    background-color: #fff7e6;
    
    padding: ${props => rem(24, props.theme)} ${props => rem(30, props.theme)};

    ${props => props.theme.breakpoints.down('xs')} {
      font-size: ${props => rem(14, props.theme)};
      line-height: ${props => rem(25, props.theme)};
    }

    ${props => props.theme.breakpoints.up('sm')} {
      padding: ${props => rem(32, props.theme)} ${props => rem(57, props.theme)};
    }
`

export const StyledContent = styled.div`
    margin-bottom: ${props => props.theme.typography.pxToRem(10)};
`

export const StyledButton = styled(Button)`
    color: #ed8b00;
    
    &:hover {
        color: #d83b00;
    }
`

const ButtonLink = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => (
  <StyledButton {...props} innerRef={ref}>
    {props.children}
  </StyledButton>),
)

export const QuickExitCTA: React.FC<PropsWithChildren<QuickExitCTAProps>> = ({ qeCTAContent, qeCTAButtonText, ...props }) => {
  const handleQuickExit = () => {
    window.open('http://www.bom.gov.au/', '_blank', 'noopener, noreferrer');
    window.history.replaceState(null, '', '/');
    window.location.replace('https://google.com.au/');
  };
  return (
    <FlexibleContent row>
      <Grid container spacing={FLEXIBLE_CONTENT_BASE_SPACING} justifyContent={'center'}>
        <Grid item xs={12}>
          <StyledWrapper>
            <StyledContent>
              {qeCTAContent && html(qeCTAContent)}
            </StyledContent>
            <ButtonLink primary buttonColor="black" { ...props} onClick={handleQuickExit}>
              {qeCTAButtonText ?? 'Quick exit'}
            </ButtonLink>
          </StyledWrapper>
        </Grid>
      </Grid>
    </FlexibleContent>
  )
}

export const QUICK_EXIT_CTA_FRAGMENT = gql`
  fragment QuickExitCTAFragment on flexibleContent_qeCTA_BlockType {
    qeCTAContent
    qeCTAButtonText  
  }
`
