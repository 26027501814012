import { gql } from '@apollo/client'
import { Grid, GridSize } from '@material-ui/core'
import React, { PropsWithChildren, useMemo } from 'react'
import {
  FLEXIBLE_CONTENT_BASE_SPACING,
  FLEXIBLE_CONTENT_LARGE_SPACING,
} from '../../../constants/flexibleContentBaseSpacing'
import { html } from '../../../utils/htmlParser'
import { FlexibleContent } from '../flexibleContent'
import { TwoColumnRowFragment } from './__generated__/twoColumnRow'

export type TwoColumnRowProps = TwoColumnRowFragment & {
  largerColumn: '' | 'left' | 'right'
  largerColumnAmount: 'small' | 'large'
}

const COLUMN_WIDTHS: Record<TwoColumnRowProps['largerColumnAmount'], readonly [GridSize, GridSize]> = {
  small: [5, 6],
  large: [4, 8],
} as const

export const TwoColumnRow: React.FC<PropsWithChildren<TwoColumnRowProps>> = ({ column1, column2, extraPadding, largerColumn, largerColumnAmount, verticallyCentre }) => {
  const columns = [column1, column2]
  const columnSizes = useMemo(() => {
    let columnsSizes: [GridSize, GridSize] = [6, 6]

    switch (largerColumn) {
    case 'left':
      columnsSizes = [COLUMN_WIDTHS[largerColumnAmount][1], COLUMN_WIDTHS[largerColumnAmount][0]]
      break
    case 'right':
      columnsSizes = [COLUMN_WIDTHS[largerColumnAmount][0], COLUMN_WIDTHS[largerColumnAmount][1]]
      break
    }

    return columnsSizes
  }, [largerColumn, largerColumnAmount])

  return (
    <FlexibleContent row>
      <Grid container spacing={extraPadding ? FLEXIBLE_CONTENT_LARGE_SPACING : FLEXIBLE_CONTENT_BASE_SPACING} alignItems={verticallyCentre ? 'center' : 'flex-start'} justifyContent={'space-between'}>
        {columns.map((col, i) => (
          <Grid item key={i} xs={12} sm={12} md={columnSizes[i]}>
            {col && html(col)}
          </Grid>
        ))}
      </Grid>
    </FlexibleContent>
  )
}

export const TWO_COLUMN_ROW_FRAGMENT = gql`
  fragment TwoColumnRowFragment on flexibleContent_twoColumnRow_BlockType {
    column1
    column2
    largerColumn
    largerColumnAmount
    extraPadding
    verticallyCentre
  }
`