import React, { PropsWithChildren } from 'react'
import { CreditUnionIcon, CreditUnionIconProps } from '../icon/CreditUnionIcon'
import {
  StyledAsterisk,
  StyledCreditUnionIconContainer,
  StyledDescription,
  StyledDescriptionContainer,
  StyledFeaturesListContainer,
  StyledFeaturesListItemWrapper,
  StyledFeaturesListSection,
  StyledTitle,
  StyledTitleContainer,
} from './styledFeaturesList'

// Icon element
export type FeaturesListIconProps = React.ComponentProps<typeof StyledCreditUnionIconContainer> & {
  iconCode: CreditUnionIconProps['iconCode']
  variant: boolean
}

export const FeaturesListIcon: React.FC<PropsWithChildren<FeaturesListIconProps>> = ({ iconCode, variant, children, ...props }) => (
  <StyledCreditUnionIconContainer {...props}>
    <CreditUnionIcon iconCode={iconCode} variant={variant}>
      {children}
    </CreditUnionIcon>
  </StyledCreditUnionIconContainer>
)

// Title element
export type FeaturesListTitleProps = React.ComponentProps<typeof StyledTitle> & {
  asterisk?: boolean
}

export const FeaturesListTitle: React.FC<PropsWithChildren<FeaturesListTitleProps>> = ({ asterisk, children, ...props }) => (
  <StyledTitleContainer>
    <StyledTitle {...props}>{children}</StyledTitle>
    {asterisk ? (<StyledAsterisk>*</StyledAsterisk>) : <></>}
  </StyledTitleContainer>
)

// Description element
export type FeaturesListDescriptionProps = React.ComponentProps<typeof StyledDescription> & {
  //
}

export const FeaturesListDescription: React.FC<PropsWithChildren<FeaturesListDescriptionProps>> = ({ children, ...props }) => (
  <StyledDescriptionContainer>
    <StyledDescription {...props} variant="body1">
      {children}
    </StyledDescription>
  </StyledDescriptionContainer>
)

export type FeaturesListContainerProps = ({ itemWrapper?: boolean; section?: boolean }) & (
  // Wrapper item
  | ({ itemWrapper: true; section?: never } & React.ComponentProps<typeof StyledFeaturesListItemWrapper>)
  // Section
  | ({ itemWrapper: never; section: true } & React.ComponentProps<typeof StyledFeaturesListSection>)
  // Container
  | React.ComponentProps<typeof StyledFeaturesListContainer>
)

export const FeaturesListContainer: React.FC<PropsWithChildren<FeaturesListContainerProps>> = ({ itemWrapper, section, children, ...props }) => {
  if (itemWrapper) {
    return (
      <StyledFeaturesListItemWrapper {...props}>   
        {children}
      </StyledFeaturesListItemWrapper>
    )
  }

  if (section) {
    return (
      <StyledFeaturesListSection {...props}>   
        {children}
      </StyledFeaturesListSection>
    )
  }

  return (
    <StyledFeaturesListContainer {...props}>
      {children}
    </StyledFeaturesListContainer>
  )
}
