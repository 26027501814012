import { Grid, Typography } from '@material-ui/core'
import { alpha } from '@material-ui/core/styles'
import styled from 'styled-components'
import { Icon } from '../icon/icon'

export const StyledTableWrapper = styled(Grid)`
  margin-top: ${props => props.theme.typography.pxToRem(-70)};
`

export const StyledTitleRow = styled(Grid)`
  background-color: rgba(255, 184, 28, 0.15);
  border-bottom: 2px solid ${props => props.theme.palette.secondary.light};
  margin-top: ${props => props.theme.typography.pxToRem(70)};
`

export const StyledTableTitle = styled(Typography)`
  color: ${props => props.theme.palette.text.secondary};
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  font-size: ${props => props.theme.typography.pxToRem(14)};
  padding-left: ${props => props.theme.typography.pxToRem(27)};
  padding-top: ${props => props.theme.typography.pxToRem(20)};
  padding-bottom: ${props => props.theme.typography.pxToRem(16)};

  ${props => props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(17)};
    padding-top: ${props => props.theme.typography.pxToRem(21)};
    padding-bottom: ${props => props.theme.typography.pxToRem(17)};
    padding-left: ${props => props.theme.typography.pxToRem(34)};
  }
`
export const StyledTableRightHeader = styled(Grid)`
  min-width: ${props => props.theme.typography.pxToRem(128)};

  ${props => props.theme.breakpoints.up('sm')} {
    min-width: ${props => props.theme.typography.pxToRem(184)};
  }

  > ${StyledTableTitle} {
    padding-left: ${props => props.theme.typography.pxToRem(27)};

    ${props => props.theme.breakpoints.up('sm')} {
      padding-left: ${props => props.theme.typography.pxToRem(34)};
    }
  }
`

export const StyledTableSubTitle = styled(Typography)`
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  line-height: ${props => props.theme.typography.pxToRem(32)};
  color: ${props => props.theme.palette.text.primary};
  font-size: ${props => props.theme.typography.pxToRem(14)};
  padding-left: ${props => props.theme.typography.pxToRem(27)};
  padding-top: ${props => props.theme.typography.pxToRem(18)};
  padding-bottom: ${props => props.theme.typography.pxToRem(16)};

  ${props => props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(20)};
    padding-top: ${props => props.theme.typography.pxToRem(35)};
    padding-bottom: ${props => props.theme.typography.pxToRem(17)};
    padding-left: ${props => props.theme.typography.pxToRem(34)};
  }
`

export const StyledTableRow = styled(Grid)`
  border-bottom: ${props => props.theme.typography.pxToRem(2)} solid ${alpha('#4d4d4d', 0.2)};
`

export const StyledTableListWrapper = styled.div`
  max-width: ${props => props.theme.typography.pxToRem(700)};
`

export const StyledListItem = styled.li`
  margin-bottom: ${props => props.theme.typography.pxToRem(20)};
`

export const StyledTableRightContainer = styled(Grid)`
  background-color: ${alpha('#f2f2f2', 0.66)};
  min-width: ${props => props.theme.typography.pxToRem(128)};

  ${props => props.theme.breakpoints.up('sm')} {
    min-width: ${props => props.theme.typography.pxToRem(184)};
  }
`

export const StyledTableContent = styled(Grid)`
  height: 100%;
  width: 100%;
`

export const StyledTickIcon = styled(Icon)`
  color: ${props => props.theme.palette.primary.main};
  width: 0.85em;
  height: 0.85em;

  ${props => props.theme.breakpoints.up('sm')} {
    width: 1em;
    height: 1em;
  }
`

export const StyledPrices = styled(Typography)`
  font-weight: 500;
  font-size: ${props => props.theme.typography.pxToRem(14)};
`

export const StyledTableText = styled(Grid)`
  font-size: ${props => props.theme.typography.pxToRem(14)};
  padding-top: ${props => props.theme.typography.pxToRem(15)};
  padding-right: ${props => props.theme.typography.pxToRem(18)};
  padding-bottom: ${props => props.theme.typography.pxToRem(12)};
  padding-left: ${props => props.theme.typography.pxToRem(27)};

  ${props => props.theme.breakpoints.up('md')} {
    font-size: ${props => props.theme.typography.pxToRem(17)};
    padding-top: ${props => props.theme.typography.pxToRem(23)};
    padding-right: ${props => props.theme.typography.pxToRem(27)};
    padding-bottom: ${props => props.theme.typography.pxToRem(26)};
    padding-left: ${props => props.theme.typography.pxToRem(34)};
  }

  > p {
    margin: 0;
  }
`

export const StyledTableDisclaimer = styled(Grid)`
  background-color: rgba(242, 242, 242, 0.5);
  margin-top: ${props => props.theme.typography.pxToRem(20)};
  padding: ${props => props.theme.typography.pxToRem(10)};
  font-size: ${props => props.theme.typography.pxToRem(12)};

  & > a {
    color: ${props => props.theme.palette.secondary.dark};
  }
`
