import { gql } from '@apollo/client'
import styled from 'styled-components'
import { rem } from '../../../utils'
import { StyledFlexibleContentRow } from '../flexibleContentRow'
import { StyledAccordionPanel } from '../../accordion/accordion'
import { StyledFcpDisclaimerBlock } from '../flexibleTableStyles'
import { StyledLinksItem } from '../../linkgrid/StyledLinkGrid'
import { StyledFeaturesListItemWrapper } from '../../FeaturesList/styledFeaturesList'
import { StyledLinkTitle } from '../../CardDesignCarousel/styledCardDesignCarousel'
import { StyledTableRightContainer } from '../../feesBenefitsTable/styledFeesBenefitsTable'

export const AltBg = styled.div<{ colour?: string }>`
  background-color: ${props => props.colour === 'grey' ? '#F9F9F9' : '#FFF7E6'};
  position: relative;
  margin: ${rem(50)} 0;
  padding: ${rem(50)} 0;

  > * + * > ${StyledFlexibleContentRow} {
    margin-block-start: ${props => props.theme.custom.spacing.paragraph};
  }

  &:first-child {
    margin-block-start: 0;
  }

  &:last-child {
    margin-block-end: 0;
  }

  ${StyledTableRightContainer} {
    background-color: ${props => props.colour === 'grey' ? 'rgba(255,244,220,0.7)' : '#FFFCF2'};
  }

  blockquote {
    background-color: ${props => props.colour === 'grey' ? 'rgba(255,244,220,0.7)' : 'white'};
  }

  ${StyledFeaturesListItemWrapper} {
    border-color: ${props => props.colour === 'grey' ? '#F2F2F2' : '#F9EACD'};
  }

  ${StyledLinkTitle}::after, ${StyledFcpDisclaimerBlock} {
    background-color: ${props => props.colour === 'grey' ? '#F2F2F2' : '#F9EACD'};
  }

  ${StyledAccordionPanel}, ${StyledLinksItem} {
    background-color: white;
  }

  ${StyledFcpDisclaimerBlock}> div:first-child {
    padding-top: ${rem(16)};
    background-color: ${props => props.colour === 'grey' ? '#F2F2F2' : '#F9EACD'} !important;
  }

  ${props => props.theme.breakpoints.up('md')} {
    margin: ${rem(120)} 0;
  }

  &::before {
    content: "";
    background-color: ${props => props.colour === 'grey' ? '#F9F9F9' : '#FFF7E6'};
    height: ${rem(30)};
    width: 100%;
    position: absolute;
    top: -${rem(30)};
    clip-path: polygon(0 0, 0 100%, 100% 100%);

    ${props => props.theme.breakpoints.up('md')} {
      top: -${rem(110)};
      height: ${rem(110)};
    }
  }

  &::after {
    content: "";
    background-color: ${props => props.colour === 'grey' ? '#F9F9F9' : '#FFF7E6'};
    height: ${rem(30)};
    width: 100%;
    position: absolute;
    bottom: -${rem(30)};
    clip-path: polygon(0 0, 0 100%, 100% 0);

    ${props => props.theme.breakpoints.up('md')} {
      bottom: -${rem(110)};
      height: ${rem(110)};
    }
  }
`

export const ALT_BG_START_FRAGMENT = gql`
  fragment AltBgStartFragment on flexibleContent_altBgStart_BlockType {
      id
      colour
    }
`

export const ALT_BG_END_FRAGMENT = gql`
  fragment AltBgEndFragment on flexibleContent_altBgEnd_BlockType {
      id
    }
`