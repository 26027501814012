import { gql } from '@apollo/client'
import React, { PropsWithChildren, useContext } from 'react'
import styled from 'styled-components'
import { CardDesignCarousel } from '../../../components/CardDesignCarousel/CardDesignCarousel'
import { CardDesignItem } from '../../../components/CardDesignCarousel/CardDesignItem'
import { IMAGE_FRAGMENT } from '../../../fragments/image'
import { GlobalContext } from '../../../globals/context'
import { truncate } from '../../../utils/truncate'
import { DetailArticlesFragment } from './__generated__/relatedArticles'

const StyleRelatedArticlesCarousel = styled(CardDesignCarousel)`
  margin-bottom: ${props => props.theme.typography.pxToRem(99)};
`

export type RelatedArticlesProps = {
  data: DetailArticlesFragment[]
}

export const RelatedArticles: React.FC<PropsWithChildren<RelatedArticlesProps>> = ({ data }) => {
  const { fallback } = useContext(GlobalContext)

  if (fallback?.globalSet?.__typename !== 'siteSettings_GlobalSet') {
    return null
  }

  const { newsFallbackImage } = fallback.globalSet

  return(
    <StyleRelatedArticlesCarousel>
      {data.map((item, index) => {
        return item.__typename !== 'news_newsEntryType_Entry' ? null : (
          <CardDesignItem key={index} href={item.uri ? `/${item.uri}` : '#'}>
            <CardDesignItem image src={item.newsDetailBanner?.[0]?.bannerImage?.[0]?.url ?? newsFallbackImage?.[0]?.url} alt="" layout="fill" objectFit="cover" objectPosition="center" />
            <CardDesignItem date>{new Date(item.postDate).toLocaleString('en-AU', { year: 'numeric', month: 'short', day: 'numeric' })}</CardDesignItem>
            <CardDesignItem title>{item?.title ? truncate(item.title, 75) : 'Untitled'}</CardDesignItem>
          </CardDesignItem>
        )})}
    </StyleRelatedArticlesCarousel>
  )
}

export const RELATED_ARTICLES_FRAGMENT = gql`
  ${IMAGE_FRAGMENT}

  fragment DetailArticlesFragment on news_newsEntryType_Entry {
    id
    uri
    title
    newsArticleDescription
    postDate
    newsDetailBanner {
      ... on newsDetailBanner_BlockType {
        id
        bannerImage {
          ...ImageFragment
        }
      }
    }
  }
`
