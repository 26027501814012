import dynamic from 'next/dynamic'
import { ComponentType } from 'react'

const sections = {
  'oneColumnRow': dynamic(() => import('./oneColumnRow').then(mod => mod.OneColumnRow)),
  'twoColumnRow': dynamic(() => import('./twoColumnRow').then(mod => mod.TwoColumnRow)),
  'threeColumnRow': dynamic(() => import('./threeColumnRow').then(mod => mod.ThreeColumnRow)),
  'fourColumnRow': dynamic(() => import('./fourColumnRow').then(mod => mod.FourColumnRow)),
  'iframe': dynamic(() => import('./iframe').then(mod => mod.iFrame)),
  'spacer': dynamic(() => import('./spacer').then(mod => mod.Spacer)),
  'relatedContent': dynamic(() => import('./relatedContent').then(mod => mod.RelatedContent)),
  'accordion': dynamic(() => import('./accordion').then(mod => mod.Accordion)),
  'peopleModule': dynamic(() => import('./people').then(mod => mod.People)),
  'tabs': dynamic(() => import('./tabs').then(mod => mod.Tabs)),
  'form': dynamic(() => import('./form').then(mod => mod.Form)),
  'disclaimer': dynamic(() => import('./disclaimer').then(mod => mod.Disclaimer)),
  'linkGrid': dynamic(() => import('./linkGrid').then(mod => mod.LinkGrid)),
  'featureGrid': dynamic(() => import('./featureGrid').then(mod => mod.FeatureGrid)),
  'carousel': dynamic(() => import('./carousel').then(mod => mod.Carousel)),
  'productTable': dynamic(() => import('./productTable').then(mod => mod.ProductTable)),
  'cta': dynamic(() => import('./cta').then(mod => mod.Cta)),
  'faqs': dynamic(() => import('./faqs').then(mod => mod.Faqs)),
  'qeCTA': dynamic(() => import('./quickExitCTA').then(mod => mod.QuickExitCTA)),
} as const

export const getBlockTypeFromTypename = (name: string) => {
  const match = name.match(/flexibleContent_(\w+)_BlockType/)

  if (!match) {
    throw new Error(`Failed to determine FCP section from ${name}`)
  }

  const [_, section] = match

  if (!section) {
    throw new Error(`Failed to determine FCP section from ${name}`)
  }

  return section
}

export const getSection = <T extends { __typename?: string }>(data: T): ComponentType => {
  if (!data.__typename) {
    throw new Error('Invalid FCP section')
  }

  const section = getBlockTypeFromTypename(data.__typename)

  return sections[section]
}