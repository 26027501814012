import { faChevronRight } from '@fortawesome/pro-solid-svg-icons'
import Grid from '@material-ui/core/Grid'
import React, { ComponentProps, PropsWithChildren } from 'react'
import { CreditUnionIcon } from '../icon/CreditUnionIcon'
import { Icon } from '../icon/icon'
import {
  BlackCardButton,
  GreenCardButton,
  StyledCardList,
  StyledCardListItem,
  StyledCardSection,
  StyledCardSubTitle,
  StyledCardText,
  StyledCardTitle,
  StyledCardWrapper,
  StyledChevronIcon,
  StyledSubSection,
  StyledTitleIcon,
} from './styledAccountCard'

type AccountCardWrapperProps = ComponentProps<typeof StyledCardWrapper> & {
  //
}

type AccountCardSectionProps = ComponentProps<typeof StyledCardSection> & {
  //
}

type AccountCardSubSectionProps = ComponentProps<typeof StyledSubSection> & {
  //
}

type AccountCardSubHeadingProps = ComponentProps<typeof StyledCardSubTitle> & {
  // 
}

type AccountCardIconHeadingProps = {
  // 
  icon?: string
}

type AccountCardListProps = ComponentProps<typeof StyledCardList> & {
  //
}

type AccountCardItemProps = ComponentProps<typeof StyledCardListItem> & {
  // 
}

type AccountCardTextProps = ComponentProps<typeof StyledCardText> & {
  // 
}

type AccountCardButtonProps = ComponentProps<typeof BlackCardButton> & {
  //
  href?: string
  green?: false | true
}

type AccountCardTitleProps = ComponentProps<typeof StyledCardTitle> & {
  // 
}


export type AccountCardProps = 
  | (
    {
      section?: never
      subsection?: never
      title?: never
      subHeading?: never
      iconHeading?: never
      list?: never
      item?: never
      text?: never
      button?: never
    } & AccountCardWrapperProps
  )
  | (
    {
      section: true
      subsection?: never
      title?: never
      subHeading?: never
      iconHeading?: never
      list?: never
      item?: never
      text?: never
      button?: never
    } & AccountCardSectionProps
  )
  | (
    {
      section?: never
      subsection: true
      title?: never
      subHeading?: never
      iconHeading?: never
      list?: never
      item?: never
      text?: never
      button?: never
    } & AccountCardSubSectionProps
  )
  | (
    {
      section?: never
      subsection?: never
      title?: never
      subHeading: true
      iconHeading?: never
      list?: never
      item?: never
      text?: never
      button?: never
    } & AccountCardSubHeadingProps
  )
  | (
    {
      section?: never
      subsection?: never
      title?: never
      subHeading?: never
      iconHeading: true
      list?: never
      item?: never
      text?: never
      button?: never
    } & AccountCardIconHeadingProps
  )
  | (
    {
      section?: never
      subsection?: never
      title?: never
      subHeading?: never
      iconHeading?: never
      list: true
      item?: never
      text?: never
      button?: never
    } & AccountCardListProps
  )
  | (
    {
      section?: never
      subsection?: never
      title?: never
      subHeading?: never
      iconHeading?: never
      list?: never
      item: true
      text?: never
      button?: never
    } & AccountCardItemProps
  )
  | (
    {
      section?: never
      subsection?: never
      title?: never
      subHeading?: never
      iconHeading?: never
      list?: never
      item?: never
      text: true
      button?: never
    } & AccountCardTextProps
  )
  | (
    {
      section?: never
      subsection?: never
      title?: never
      subHeading?: never
      iconHeading?: never
      list?: never
      item?: never
      text?: never
      button: true
    } & AccountCardButtonProps
  )
  | (
    {
      section?: never
      subsection?: never
      title: true
      subHeading?: never
      iconHeading?: never
      list?: never
      item?: never
      text?: never
      button?: never
    } & AccountCardTitleProps
  )

export const AccountsCard: React.FC<PropsWithChildren<AccountCardProps>> = ({ section, title, subHeading, subsection, iconHeading, list, item, text, button, children, ...props }) => {
  if (section) {
    return (
      <CardSection {...props}>{children}</CardSection>
    )
  }

  if (subsection) {
    return (
      <StyledSubSection item >{children}</StyledSubSection>
    )
  }

  if (title) {
    return (
      <StyledCardTitle item md={4} {...props}>{children}</StyledCardTitle>
    )
  }

  if (subHeading) {
    return (
      <StyledCardSubTitle {...props}>{children}</StyledCardSubTitle>
    )
  }

  if (iconHeading) {
    return (
      <HeadingWithIcon {...props}>{children}</HeadingWithIcon>
    )
  }

  if (list) {
    return (
      <StyledCardList {...props}>{children}</StyledCardList>
    )
  }

  if (item) {
    return (
      <CardListItem {...props}>{children}</CardListItem>
    )
  }

  if (text) {
    return (
      <StyledCardText {...props}>{children}</StyledCardText>
    )
  }

  if (button) {
    return (
      <CardButton {...props}>{children}</CardButton>
    )
  }

  return (
    <CardWrapper {...props}>{children}</CardWrapper>
  )
}

const CardSection: React.FC<PropsWithChildren<AccountCardSectionProps>> = ({ children, ...props }) => {
  return (
    <StyledCardSection
      item
      md={4}
    >
      <Grid 
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        {...props}
      >
        {children}
      </Grid>
    </StyledCardSection>
  )
}

const CardWrapper: React.FC<PropsWithChildren<AccountCardWrapperProps>> = ({ children, ...props }) => {
  return (
    <StyledCardWrapper item md={12} {...props}>
      <Grid
        container
        direction="row" 
        spacing={4} 
        justifyContent="center" 
        alignItems="stretch"
      >
        {children}
      </Grid>
    </StyledCardWrapper>
  )
}

const HeadingWithIcon: React.FC<PropsWithChildren<AccountCardIconHeadingProps>> = ({ icon, children, ...props }) => {
  return (
    <StyledCardSubTitle container direction="row" justifyContent="flex-start" alignItems="center" wrap="nowrap" {...props}>
      {icon && <StyledTitleIcon><CreditUnionIcon variant={true} width="29" height="29" iconCode={icon}/></StyledTitleIcon>
      }
      {children}
      <StyledChevronIcon icon={faChevronRight} />
    </StyledCardSubTitle>
  )
}

const CardButton: React.FC<PropsWithChildren<AccountCardButtonProps>> = ({ href, green=false, children, ...props }) => {
  if (green) {
    return (
      <GreenCardButton href={href} {...props}>{children}</GreenCardButton>
    )
  }

  return (
    <BlackCardButton href={href} {...props}>{children}</BlackCardButton>
  )
}

const CardListItem: React.FC<PropsWithChildren<AccountCardItemProps>> = ({ children, ...props }) => {
  return (
    <StyledCardListItem {...props}><span><Icon inline icon={faChevronRight} /></span>{children}</StyledCardListItem>
  )
}