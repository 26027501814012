import { gql } from '@apollo/client'
import Grid from '@material-ui/core/Grid'
import { Alert } from '@material-ui/lab'
import React, { MutableRefObject, PropsWithChildren, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { SimpleBoundary } from '../../../boundaries/simple'
import { rem } from '../../../utils'
import { html } from '../../../utils/htmlParser'
import { StyledSvgIcon } from '../../icon/icon'
import { FlexibleContent } from '../flexibleContent'
import { FormFragment } from './__generated__/form'
import Script from 'next/script'

export type FormProps = FormFragment

export const Form: React.FC<PropsWithChildren<FormProps>> = ({ form }) => {
  const wrapperRef = useRef() as MutableRefObject<HTMLDivElement>
  const [formEl, setFormEl] = useState<HTMLFormElement>()
  const reqField = useMemo(() => formEl?.querySelectorAll('.freeform-required'), [formEl])
  const [isPending, setIsPending] = useState(false)
  const [error, setError] = useState<string>()
  const [message, setMessage] = useState<string>()
  const [complete, setComplete] = useState(false)

  useLayoutEffect(() => {
    if (wrapperRef.current) {
      setFormEl(wrapperRef.current.querySelector<HTMLFormElement>('[method="post"]') ?? undefined)
    }
  }, [wrapperRef])

  // Freeform plugin does not apply required attribute
  useEffect(() => {
    if (reqField) {
      for (const element of Array.from(reqField)) {
        const sibling = element.nextElementSibling

        if (sibling) {
          sibling.setAttribute('required', 'required')
        }
      }
    }
  }, [reqField])

  const onSubmit = useCallback(async (form) => {
    setIsPending(true)
    setMessage(undefined)
    setError(undefined)

    const data = new FormData(form)

    // Freeform plugin out of the box validation does not want to work...
    const email = data.get('email')

    if (email && typeof email === 'string' && !/^.+?@.+$/.test(email)) {
      setIsPending(false)
      setError('Invalid email address')
    } else {
      try {
        form.form_page_submit.setAttribute('disabled', 'disabled')

        const { returnUrl, success, errors } = await fetch(`${process.env.NEXT_PUBLIC_API_BASE ? process.env.NEXT_PUBLIC_API_BASE : 'https://cms.creditunion.atomix.dev'}`, {
          method: 'POST',
          body: data,
          redirect: 'error',
        }).then(res => res.json())

        if (success) {
          setComplete(true)
          setMessage('Form successfully submitted')

          if (returnUrl) {
            setIsPending(false)

            window.location.href = returnUrl
          }
        } else {
          form.form_page_submit.removeAttribute('disabled')

          const firstError = Object.values(errors as Record<string, string[]>)[0]?.[0]

          setIsPending(false)
          setError(firstError || 'Form submission failed')
        }
      } catch (error) {
        form.form_page_submit.removeAttribute('disabled')
        setIsPending(false)
        setError(`Form submission failed with message: ${error.message}`)
      }
    }
  }, [])

  useEffect(() => {
    if (formEl) {
      formEl.addEventListener('submit', (e) => {
        e.preventDefault()
        onSubmit(formEl)
      })
    }
  }, [formEl, onSubmit])

  return (
    <FlexibleContent row keepMargins>
      {message && (
        <Alert severity={'success'} icon={false}>
          {message}
        </Alert>
      )}
      {error && (
        <Alert severity={'error'} icon={false}>
          {error}
        </Alert>
      )}
      <Grid container justifyContent={'center'}>
        {form && form.reCaptcha && form.reCaptcha.enabled && (
          <Script
            src="https://www.google.com/recaptcha/api.js"
            strategy="afterInteractive"
          />
        )}
        <SimpleBoundary>
          {form && form.html && (
            <StyledFormWrapper item xs={12} md={8} ref={wrapperRef} $pending={isPending} style={{ display: complete ? 'none' : 'block' }}>
              {html(form.html)}
            </StyledFormWrapper>
          )}
        </SimpleBoundary>
      </Grid>
    </FlexibleContent>
  )
}

export const FORM_FRAGMENT = gql`
  fragment FormFragment on flexibleContent_form_BlockType {
    form {
      html
      reCaptcha {
        name
        handle
        enabled
      }
    }
  }
`

const structure = css`
  margin-top: ${props => rem(-20, props.theme)};

  .freeform-column {
    margin-top: ${props => rem(20, props.theme)};
    display: flex;
    flex-direction: column;
  }

  .freeform-row {
    + .freeform-row {
      ${props => props.theme.breakpoints.up('sm')} {
        margin-top: ${props => props.theme.spacing(-3)};
      }
    }
  }

  ${props => props.theme.breakpoints.up('sm')} {
    margin-top: 0;

    .freeform-column {
      margin-top: 0;
    }
  }
`

const honeypot = css`
  .freeform_form_handle {
    display: none;
  }
`

const label = css`
  .freeform-label {
    margin-left: ${props => props.theme.spacing(2)};
    font-weight: 500;
    font-size: ${props => props.theme.typography.pxToRem(15)};

    ${props => props.theme.breakpoints.up('sm')} {
      font-size: ${props => props.theme.typography.pxToRem(17)};
    }

    &.freeform-required {
      &::after {
        content: '*';
        margin-left: 0.25em;
        color: ${props => props.theme.palette.error.main};
      }
    }
  }
`

const inputBase = css`
  font-family: ${props => props.theme.typography.fontFamily};
  font-size: ${props => rem(17, props.theme)};
  padding: ${props => rem(15, props.theme)} ${props => rem(21, props.theme)};
  border-radius: ${props => rem(4, props.theme)};
  border: 1px solid rgba(112, 112, 112, 0.18);
`

const button = css`
  [type="submit"] {
    align-self: flex-start;
    padding: 0.8823529411764706rem 0.6470588235294118rem 0.8823529411764706rem 0.8823529411764706rem;
    border: 1px solid #f35a21;
    font-size: ${props => rem(18, props.theme)}

    & span {
      font-size: ${props => rem(18, props.theme)}

      ${props => props.theme.breakpoints.up('md')} {
        font-size: ${props => rem(15, props.theme)};
      }
    }

    &::after {
      height: ${props => rem(10, props.theme)};

      ${props => props.theme.breakpoints.up('md')} {
        height: ${props => rem(15, props.theme)};
      }
    }
  }
`

const input = css`
  input {
    ${inputBase}
  }
`

const textarea = css`
  textarea {
    ${inputBase}
  }
`

const checkboxStyles = css`
  display: none;

  + .fake-radio {
    position: relative;
    display: block;
    width: ${props => rem(26, props.theme)};
    height: ${props => rem(26, props.theme)};
    border: 1px solid rgba(77, 77, 77, 0.5);
    border-radius: 50%;
    margin: 0 ${props => props.theme.spacing(2)} ${props => rem(5, props.theme)} 0;

    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(0);
      content: "";
      background: ${props => props.theme.palette.primary.main};
      width: ${props => rem(8, props.theme)};
      height: ${props => rem(8, props.theme)};
      border-radius: 50%;
      transition: transform 0.2s ease-in-out;
    }
  }

  &:checked + .fake-radio {
    border-color: ${props => props.theme.palette.primary.main};
    border-width: 2px;

    &::after {
      transform: translate(-50%, -50%) scale(1);
    }
  }
`

const radio = css`
  .freeform-radio-label {
    ${inputBase};

    display: flex;
    align-items: center;
    padding: ${props => rem(12, props.theme)} ${props => props.theme.spacing(2)} ${props => rem(7, props.theme)};
    background: white;
    line-height: 1;
    cursor: pointer;

    + .freeform-radio-label {
      margin-top: ${props => rem(13, props.theme)};
    }

    input[type="radio"] {
      ${checkboxStyles};
    }

    input[type="checkbox"] {
      ${checkboxStyles};

      + .fake-radio {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;

        &::after {
          display: none;
        }

        ${StyledSvgIcon} {
          transition: transform 0.2s ease-in-out;
          transform: scale(0);
          font-size: ${props => props.theme.typography.pxToRem(12)};
          fill: ${props => props.theme.palette.primary.main};
        }
      }

      &:checked + .fake-radio {
        ${StyledSvgIcon} {
          transform: scale(1);
        }
      }
    }
  }
`

export const StyledFormWrapper = styled(Grid)<{ $pending?: boolean }>`
  transition: opacity 0.2s ease-in-out;
  will-change: opacity;
  opacity: ${props => props.$pending ? 0.3 : 1};

  .MuiButton-containedPrimary > span {
    font-size: ${props => props.theme.typography.pxToRem(18)};
  }

  ${structure}
  ${honeypot}
  ${label}
  ${input}
  ${textarea}
  ${radio}
  ${button}
`
