import { gql } from '@apollo/client'
import Grid from '@material-ui/core/Grid'
import React, { PropsWithChildren, useMemo } from 'react'
import { RATES_TABLE_FRAGMENT, RatesTable } from '../../../fragments/ratesTable'
import { html } from '../../../utils/htmlParser'
import { nonNull } from '../../../utils/nonNull'
import { Table } from '../../feesBenefitsTable/feesBenefitsTable'
import { FlexibleContent } from '../flexibleContent'
import {
  StyledFcpDisclaimerBase,
  StyledFcpDisclaimerBlock,
  StyledFcpTableHeading,
} from '../flexibleTableStyles'
import { ProductTablesFragment } from './__generated__/productTable'

export type FeesTableProps = ProductTablesFragment

export type NonApiArrayType = {
  disclaimerText: string
}

export const ProductTable: React.FC<PropsWithChildren<FeesTableProps>> = ({ table: tables }) => {
  const table = tables?.[0]

  if (!table) {
    return null
  }

  const inner: React.ReactNode = useMemo(() => {
    switch (table.__typename) {
    case 'productFeesTables_default_Entry':
      return (
        <Grid container justifyContent={'center'}>
          <Grid item xs={12}>
            <StyledFcpTableHeading>{table?.tableTitle}</StyledFcpTableHeading>
          </Grid>
          <Grid item xs={12} sm={10} md ={8}>
            <Table>
              <Table title>
                <Table titleLeft>{'Fee'}</Table>
                <Table titleRight>{'Amount'}</Table>
              </Table>
              {table.productFeesTable?.[0]?.__typename === 'productFeesTable_BlockType' && table.productFeesTable[0].feesTableContent && (
                nonNull(table.productFeesTable[0].feesTableContent).map((content, index) => {
                  if (content.__typename === 'feesTableContent_subTitle_BlockType') {
                    return (
                      <Table row key={index}>
                        <Table subTitle>
                          {content.leftSubtitle}
                        </Table>
                      </Table>
                    )
                  } else if (content.__typename === 'feesTableContent_row_BlockType') {
                    return (
                      <Table row key={index}>
                        {content.leftColumnContent &&
                        <Table contentLeft>
                          <Table text>{html(content.leftColumnContent)}</Table>
                        </Table>
                        }
                        {content.rightColumnContent &&
                        <Table contentRight>
                          <Table text>{html(content.rightColumnContent)}</Table>
                        </Table>
                        }
                      </Table>
                    )
                  }
                })
              )}
            </Table>
            {table.productFeesTable?.[0]?.__typename === 'productFeesTable_BlockType' && table.productFeesTable[0].dislaimer && (
              <StyledFcpDisclaimerBlock>
                { nonNull(table.productFeesTable[0].dislaimer).map((item, index) => {
                  if (item.__typename === 'customDisclaimers_default_Entry' || item.__typename === 'homeLoanDisclaimers_default_Entry' || item.__typename === 'personalloandisclaimers_default_Entry') {
                    return (
                      <StyledFcpDisclaimerBase key={index} textBlock>
                        {item.disclaimerText && html(item.disclaimerText)}
                      </StyledFcpDisclaimerBase>
                    )
                  }

                  return null
                })}
              </StyledFcpDisclaimerBlock>
            )}
          </Grid>
        </Grid>
      )
    case 'productBenefitsTables_default_Entry':
      return (
        <Grid container justifyContent = {'center'}>
          <Grid item xs={12}>
            <StyledFcpTableHeading>{table?.tableTitle ?? 'KEY BENEFITS'}</StyledFcpTableHeading>
          </Grid>
          <Grid item xs={12} sm={10} md ={8}>
            <Table>
              {table?.productBenefitsTable?.[0]?.__typename === 'productBenefitsTable_BlockType' && table.productBenefitsTable[0].tableHeading && (
                <Table title>
                  <Table titleLeft>{table.productBenefitsTable[0].tableHeading}</Table>
                </Table>
              )}
              {table.productBenefitsTable?.[0]?.__typename === 'productBenefitsTable_BlockType' && table.productBenefitsTable[0].benefitsTableContent && (
                nonNull(table.productBenefitsTable[0].benefitsTableContent).map((content, index) => {
                  if ( content.__typename === 'benefitsTableContent_subTitleBenefits_BlockType') {
                    return (
                      <Table row key={index}>
                        <Table subTitle>
                          {content.leftSubtitleBenefits}
                        </Table>
                      </Table>
                    )
                  } else if ( content.__typename === 'benefitsTableContent_contentBenefits_BlockType') {
                    return(
                      <>
                        { content.leftColumnContentBenefits &&
                        <Table row kwy ={index}>
                          <Table contentLeft>
                            <Table text>{html(content.leftColumnContentBenefits)}</Table>
                          </Table>
                          <Table contentRight/>
                        </Table>}
                      </>
                    )
                  }
                })
              )}
            </Table>
          </Grid>
        </Grid>
      )
    case 'rateTables_default_Entry':
      return (
        <RatesTable { ...table } />
      )
    default:
      return null
    }
  }, [table])

  return (
    <FlexibleContent row keepMargins>
      {inner}
    </FlexibleContent>
  )
}

export const PRODUCT_TABLES_FRAGMENT = gql`
  ${RATES_TABLE_FRAGMENT}
  
  fragment ProductTablesFragment on flexibleContent_productTable_BlockType {
    table {
      ...RatesTableFragment
      ... on productFeesTables_default_Entry {
        tableTitle
        productFeesTable {
          ... on productFeesTable_BlockType {
            feesTableContent {
              ... on feesTableContent_subTitle_BlockType {
                leftSubtitle
              }
              ... on feesTableContent_row_BlockType {
                leftColumnContent
                rightColumnContent
              }
            }
            dislaimer {
              ... on customDisclaimers_default_Entry {
                disclaimerText
              }
              ... on homeLoanDisclaimers_default_Entry {
                disclaimerText
              }
              ... on personalloandisclaimers_default_Entry {
                id
                disclaimerText
              }
            }
          }
        }
      }
      ... on productBenefitsTables_default_Entry {
        tableTitle
        productBenefitsTable {
          ... on productBenefitsTable_BlockType {
            tableHeading
            benefitsTableContent {
              ... on benefitsTableContent_subTitleBenefits_BlockType {
                leftSubtitleBenefits
              }
              ... on benefitsTableContent_contentBenefits_BlockType {
                leftColumnContentBenefits
              }
            }
          }
        }
      }
    }
  }
`
