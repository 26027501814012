import { gql } from '@apollo/client'
import Grid from '@material-ui/core/Grid'
import React, { PropsWithChildren } from 'react'
import { FLEXIBLE_CONTENT_BASE_SPACING } from '../../../constants/flexibleContentBaseSpacing'
import { html } from '../../../utils/html'
import { FlexibleContent } from '../flexibleContent'
import { FourColumnRowFragment } from './__generated__/fourColumnRow'

export type FourColumnRowProps = FourColumnRowFragment & {
  verticallyCentre: boolean
}

export const FourColumnRow: React.FC<PropsWithChildren<FourColumnRowProps>> = ({ column1, column2, column3, column4, verticallyCentre }) => {
  const columns = [column1, column2, column3, column4]

  return (
    <FlexibleContent row>
      <Grid container spacing={FLEXIBLE_CONTENT_BASE_SPACING} alignItems={verticallyCentre ? 'center' : 'flex-start'}>
        {columns.map((col, i) => (
          <Grid item key={i} xs={12} sm={6} md={3}>
            {col && html(col)}
          </Grid>
        ))}
      </Grid>
    </FlexibleContent>
  )
}

export const FOUR_COLUMN_ROW_FRAGMENT = gql`
  fragment FourColumnRowFragment on flexibleContent_fourColumnRow_BlockType {
    column1
    column2
    column3
    column4
    verticallyCentre
  }
`