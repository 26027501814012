import { Typography } from '@material-ui/core'
import { alpha } from '@material-ui/core/styles'
import Image from 'next/legacy/image'
import styled, { css } from 'styled-components'
import { irem, rem } from '../../utils'

export const StyleImageProfileContainer = styled.div`
  flex: 0 0 auto;
  position: relative;
  border-radius: 50%;
  border: ${props => rem(6, props.theme)} solid white;
  box-shadow: 0 ${props => rem(3, props.theme)} ${props => rem(15, props.theme)} rgba(0, 0, 0, 0.1);

  ${props => props.theme.breakpoints.up('md')} {
    border-width: ${props => rem(10, props.theme)};
  }
`

export const StyleImageProfile = styled(Image)`
  border-radius: 50%;
`

export const StyledPeopleModuleContainer = styled.div<{ $showHr?: boolean; $variant: 'people' | 'author' }>`
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: ${props => props.theme.typography.pxToRem(props.$showHr ? 29 : 20)};
  padding-top: ${props => props.theme.typography.pxToRem(36)};
  margin-bottom: ${props => props.theme.typography.pxToRem(10)};

  ${StyleImageProfileContainer} {
    width: ${props => rem(props.$variant === 'author' ? 73 : 100, props.theme)};
    height: ${props => rem(props.$variant === 'author' ? 73 : 100, props.theme)};

    ${props => props.theme.breakpoints.up('sm')} {
      width: ${props => rem(props.$variant === 'author' ? 108 : 172, props.theme)};
      height: ${props => rem(props.$variant === 'author' ? 108 : 172, props.theme)};
      border-width: ${props => rem(props.$variant === 'author' ? 6 : 0, props.theme)};
    }
  }

  ${props => props.theme.breakpoints.up('sm')} {
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    row-gap: 0;
    column-gap: ${props => props.theme.typography.pxToRem(66)};

    ${props => props.$variant === 'author' && css`
      align-items: flex-end;
      column-gap: ${props => props.theme.typography.pxToRem(28)};

      ${StyleImageWrapper} {
        width: ${props => rem(50, props.theme)};
      }

      ${AuthorInformationWrapper} {
        margin-bottom: ${props => rem(-8, props.theme)};
      }
    `}

    ${props => props.$variant === 'people' && css`
      ${StyleImageProfile} {
        width: calc(100% - ${irem(20)}) !important;
        height: calc(100% - ${irem(20)}) !important;
        min-width: calc(100% - ${irem(20)}) !important;
        min-height: calc(100% - ${irem(20)}) !important;
        max-width: calc(100% - ${irem(20)}) !important;
        max-height: calc(100% - ${irem(20)}) !important;
      }
    `}
  }

  ${props => props.$showHr && css`
    border-bottom: solid ${props => props.theme.typography.pxToRem(2)} ${props => alpha(props.theme.palette.text.secondary, 0.19)};
  `}

  ${props => props.$variant === 'author' && css`
    padding-top: 0;
    margin-bottom: 0;

    ${StyledPeopleModuleGroupUserInfo}, ${AuthorInformationWrapper} {
      padding: 0;
    }
  `}
`

export const StyledPeopleModuleGroupProfile = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: ${props => props.theme.typography.pxToRem(12)};

  ${props => props.theme.breakpoints.up('sm')} {
    width: auto;
  }
`

export const StyledPeopleModuleGroupUserInfo = styled.div`
  padding-bottom: ${props => props.theme.typography.pxToRem(40)};

  ${props => props.theme.breakpoints.up('sm')} {
    padding-top: ${props => props.theme.typography.pxToRem(35)};
    padding-bottom: ${props => props.theme.typography.pxToRem(50)};
    border-bottom: none;
    margin-right: ${props => props.theme.typography.pxToRem(40)};
  }
`

export const StyledPeopleModuleHeading = styled.div`
  padding-right: 0;
`

export const StyledPeopleModuleName = styled(Typography)<{$type?: 'people' | 'author'}>`
  font-weight: 500;
  font-size: ${props => props.theme.typography.pxToRem(props.$type === 'author' ? 19 : 23)};
  margin-bottom: ${props => props.theme.typography.pxToRem(props.$type === 'author' ? 8 : 0)};
  line-height: ${props => props.theme.typography.pxToRem(30)};

  ${props => props.theme.breakpoints.up('sm')} {
    line-height: ${props => props.theme.typography.pxToRem(45)};
    font-size: ${props => props.theme.typography.pxToRem(props.$type === 'author' ? 22 : 32)};
    ${props => props.$type === 'author' && css`margin-bottom: -0.3em;` }
  }
`

export const StyledPeopleModuleTitle = styled(Typography)<{$type?: 'people' | 'author'}>`
  font-size: ${props => props.theme.typography.pxToRem(13)};
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  margin-top: 0;

  ${props => props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(props.$type === 'author' ? 15 : 17)};
    margin-top: ${props => props.theme.typography.pxToRem(14)};
    ${props => props.$type === 'author' && css`margin-bottom: 0.3em;` }
  }
`

export const StyledEducationCredentials = styled(Typography)`
  font-size: ${props => props.theme.typography.pxToRem(13)};

  ${props => props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(15)};
    padding-top: ${props => props.theme.typography.pxToRem(16)};
  }
`

export const StyledContact = styled(Typography)`
  font-size: ${props => props.theme.typography.pxToRem(13)};
  font-weight: 500;
  background-color: ${props => props.theme.palette.background.default};
  border: solid ${props => props.theme.typography.pxToRem(2)} ${props => alpha(props.theme.palette.text.secondary, 0.19)};
  border-radius: ${props => props.theme.typography.pxToRem(4)};
  padding: ${props => props.theme.typography.pxToRem(12)} 0 ${props => props.theme.typography.pxToRem(9)} 0;
  text-align: center;
  width: 100%;
  white-space: nowrap;

  ${props=> props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(15)};
    text-align: left;
    background-color: transparent;
    border-radius: 0;
    border: none;
    width: auto;
    padding: 0;
  }

  > a {
    text-decoration: none;
    color: ${props => props.theme.palette.text.secondary};

    > span:first-child {
      color: ${props => props.theme.palette.secondary.dark};
      ${props=> props.theme.breakpoints.up('sm')} {
        color: ${props => props.theme.palette.text.secondary};
      }
    }

    > span:last-child {
      ${props=> props.theme.breakpoints.up('sm')} {
        color: ${props => props.theme.palette.secondary.dark};
        text-decoration: underline;
      }

      &:hover {
        color: ${props => props.theme.palette.secondary.main};
      }
    }
  }
`

export const StyledContactContainer = styled.div`
  width: 100%;
  ${props=> props.theme.breakpoints.up('sm')} {
    margin-top: ${props => props.theme.typography.pxToRem(40)};
  }
`

export const StyledVerticalContactPhoneContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${props => props.theme.typography.pxToRem(10)};
  margin-bottom: ${props => props.theme.typography.pxToRem(9)};
`

export const StyledDescription = styled.div`
  font-size: ${props => props.theme.typography.pxToRem(15)};
  padding-top: ${props => props.theme.typography.pxToRem(38)};

  ${props => props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(17)};
    padding-top: ${props => props.theme.typography.pxToRem(40)};
  }

  p:last-of-type {
    margin-bottom: 0;
  }
`

export const StyleImageWrapper = styled.div<{$width: number; $height: number}>`
  width: ${props => props.theme.typography.pxToRem(props.$width)};
  height: ${props => props.theme.typography.pxToRem(props.$height)};
`

export const AuthorInformationWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: ${props => props.theme.typography.pxToRem(4)};
  padding-bottom: ${props => props.theme.typography.pxToRem(10)};

  ${props => props.theme.breakpoints.up('sm')} {
    padding-bottom: 0;
    padding-top: ${props => props.theme.typography.pxToRem(16)};
    gap: ${props => props.theme.typography.pxToRem(16)};
    max-width: ${props => props.theme.typography.pxToRem(600)};
  }
`

export const AuthorLastUpdatedDate = styled(Typography)`
  font-size: ${props => props.theme.typography.pxToRem(15)};
  white-space: nowrap;
`

export const AuthorSeparator = styled.span`
  font-size: ${props => props.theme.typography.pxToRem(15)};
  padding: 0 ${props => props.theme.typography.pxToRem(16)};
`

export const AuthorCategory = styled.a`
  font-size: ${props => props.theme.typography.pxToRem(15)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
