import { gql } from '@apollo/client'
import { Grid } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'
import { FLEXIBLE_CONTENT_BASE_SPACING } from '../../../constants/flexibleContentBaseSpacing'
import { irem } from '../../../utils'
import { html } from '../../../utils/html'
import { nonNull } from '../../../utils/nonNull'
import {
  Accordion as AccordionBase,
  AccordionContentContainer,
  StyledAccordionHeading,
  StyledAccordionPanel, StyledColumn,
} from '../../accordion/accordion'
import { FlexibleContent } from '../flexibleContent'
import { AccordionFragment, AccordionPanelFragment } from './__generated__/accordion'

export type AccordionProps = AccordionFragment

const StyledAccordionBase = styled(AccordionBase)`
  ${StyledAccordionPanel} {
    margin-right: 0;

    ${props => props.theme.breakpoints.up('md')} {
      margin-bottom: ${irem(25)};
    }
  }

  ${StyledColumn} {
    padding-right: 0;

    + ${StyledColumn} {
      margin-top: 0;
    }
  }

  ${props => props.theme.breakpoints.up('md')} {
    ${StyledColumn}:first-child {
      padding-right: ${irem(10)};
    }

    ${StyledColumn}:last-child {
      padding-right: 0;
      padding-left: ${irem(10)};
    }
  }

  ${StyledAccordionHeading}, ${AccordionContentContainer} {
    padding-left: ${irem(23)};
    padding-right: ${irem(23)};

    ${props => props.theme.breakpoints.up('md')} {
      padding-left: ${irem(35)};
      padding-right: ${irem(35)};
    }
  }

  ${AccordionContentContainer} {
    padding-bottom: ${irem(20)};

    ${props => props.theme.breakpoints.down('xs')} {
      padding-top: 0;
    }

    ${props => props.theme.breakpoints.up('md')} {
      padding-bottom: ${irem(45)};
    }

    > :last-child {
      margin-bottom: 0;
    }
  }
`

export const Accordion: React.FC<PropsWithChildren<AccordionProps>> = ({ columns: columnCount, accordion }) => {
  if (!columnCount || !accordion) {
    return null
  }

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const columns = Array.from({ length: Number(columnCount) }) as AccordionPanelFragment[][]

  if (columnCount === '1') {
    columns[0] = nonNull(accordion)
  } else if (columnCount === '2') {
    columns[0] = []
    columns[1] = []

    for (const [i, panel] of nonNull(accordion).entries()) {
      (columns[i % 2] as AccordionPanelFragment[]).push(panel)
    }
  }

  const handleClickAccordion = (event) => {
    if (isMobile && typeof window === 'object') {
      const currentTarget = event.currentTarget

      setTimeout(() => {
        currentTarget.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        })
      }, 250)
    }
  }

  return (
    <FlexibleContent row>
      <Grid container spacing={FLEXIBLE_CONTENT_BASE_SPACING} justifyContent={'center'}>
        <Grid item xs={12} sm={12} md={columns.length === 1 ? 10 : 12}>
          <StyledAccordionBase>
            {columns.map((panels, i) => {

              return columns.length === 1 ? (
                <>
                  {panels.map((panel) => (
                    <AccordionBase panel key={panel.id} onClick={handleClickAccordion}>
                      <AccordionBase title>{panel.panelTitle}</AccordionBase>
                      {panel.panelBody && (
                        <AccordionBase content>{html(panel.panelBody)}</AccordionBase>
                      )}
                    </AccordionBase>
                  ))}
                </>
              ) : (
                <AccordionBase column key={i}>
                  {panels.map((panel) => (
                    <AccordionBase panel key={panel.id} onClick={handleClickAccordion}>
                      <AccordionBase title>{panel.panelTitle}</AccordionBase>
                      {panel.panelBody && (
                        <AccordionBase content>{html(panel.panelBody)}</AccordionBase>
                      )}
                    </AccordionBase>
                  ))}
                </AccordionBase>
              )
            }
            )}
          </StyledAccordionBase>
        </Grid>
      </Grid>
    </FlexibleContent>
  )
}

export const ACCORDION_FRAGMENT = gql`
  fragment AccordionPanelFragment on accordion_BlockType {
    id
    panelTitle
    panelBody
  }
  
  fragment AccordionFragment on flexibleContent_accordion_BlockType {
    columns
    accordion {
      ...AccordionPanelFragment
    }
  }
`