import { gql } from '@apollo/client'
import React, { PropsWithChildren } from 'react'
import { PeopleModule } from '../../../components/peoplemodule/PeopleModule'
import { IMAGE_FRAGMENT } from '../../../fragments/image'
import { NewsAuthorFragment } from './__generated__/peopleModule'

type CategoryDataType = {
  id: string
  title: string
  url: string
  __typename: string
}

export type NewsAuthorProps = {
  authorData: NewsAuthorFragment[]
  categoryData?: CategoryDataType[]
  dateUpdated?: string
}

export const AuthorSegment: React.FC<PropsWithChildren<NewsAuthorProps>> = ({ authorData, categoryData, dateUpdated }) => {
  if (!authorData && !categoryData && !dateUpdated) {
    return null
  }

  const author = authorData?.[0]?.teamAuthorName?.[0]
  const category = categoryData?.[0]

  const imageUrl = author?.peopleImage?.[0]?.url
  const name = `${author?.peopleFirstName ?? ''} ${author?.peopleLastName ?? ''}`
  const designation = author?.peopleDesignation ?? ''

  return (
    <PeopleModule variant="author">
      {imageUrl && (
        <PeopleModule avatar src={imageUrl} />
      )}
      <PeopleModule heading>
        {name && <PeopleModule name type="author">{name}</PeopleModule>}
        {designation && (
          <PeopleModule title type="author">{designation}</PeopleModule>
        )}
      </PeopleModule>
      {(category || dateUpdated) && (
        <PeopleModule authorInfo href={category ? `/community/news?category=${category.id}` : undefined}>
          {dateUpdated && `${new Date(dateUpdated).toLocaleString('en-AU', { year: 'numeric', month: 'short', day: 'numeric' })}`}
          {category?.title}
        </PeopleModule>
      )}
    </PeopleModule>
  )
}

export const NEWS_AUTHOR_FRAGMENT = gql`
  ${IMAGE_FRAGMENT}

  fragment NewsAuthorFragment on peopleChannel_default_Entry {
    teamAuthorName {
      ... on teamAuthorName_BlockType {
        id
        peopleFirstName
        peopleLastName
        peopleDesignation
        peopleImage {
          ...ImageFragment
        }
      }
    }
  }
`
